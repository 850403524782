<template>
  <!-- class="fullScreen" -->
  <div :class="{ show: showEditDialog, fullScreen: distriBution }">
    <div v-loading="loading">
      <Crumbs v-if="distriBution">
        <el-breadcrumb-item slot="after"> {{ title }} </el-breadcrumb-item>
      </Crumbs>
      <div :class="{ fullScreenMain: distriBution }" id="Scrollbar">
        <div :class="{ fullScreenMainCon: distriBution }">
          <template v-if="businessInformation && !isModule">
            <el-descriptions style="margin-top: 10px" direction="vertical" :column="5" border>
              <template slot="title">
                <div class="info-title-with-button">
                  <div style="font-size: 16px; color: #000; font-weight: 700; margin-bottom: 5px">
                    商务基础信息
                  </div>
                  <el-button type="success" size="small">
                    <a
                      target="_blank"
                      href=" https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/项目商务绩效分配方法.pdf"
                      >绩效分配规则说明</a
                    ></el-button
                  >
                </div>
              </template>
              <el-descriptions-item label="商务名称" :span="2">
                {{ businessInformation.businessName }}
              </el-descriptions-item>
              <el-descriptions-item label="商务类型" :span="1">
                {{ businessInformation.businessType | dict(dictData.businessType) }}
              </el-descriptions-item>
              <el-descriptions-item label="项目来源" :span="1">
                {{ businessInformation.projectSource | dict(dictData.projectSource) }}
              </el-descriptions-item>
              <el-descriptions-item label="业务种类" :span="1">
                {{ businessInformation.businessLines | dict(dictData.businessLines) }}
              </el-descriptions-item>
              <el-descriptions-item label="业主单位" :span="3">
                {{ businessInformation.ownerUnit }}
              </el-descriptions-item>
              <el-descriptions-item label="项目承接公司">
                {{ businessInformation.companyType | dict(dictData.companyType) }}
              </el-descriptions-item>
              <el-descriptions-item label="实际商务负责人">
                {{ businessInformation.principalUserName | addDept(CompanyMembers) }}
              </el-descriptions-item>
            </el-descriptions>
          </template>

          <el-table
            :data="tableData[Index]"
            border
            id="refTable"
            ref="refTable"
            @cell-click="cellClickFn"
            style="width: 100%"
          >
            <el-table-column type="expand" width="85">
              <template slot="header">
                <span style="color: red"></span>
              </template>
              <template slot-scope="scope">
                <div style="padding-left: 3%">
                  <!-- 无事前沟通竞标类项目 -->
                  <template v-if="scope.row.businessType === '无事前沟通竞标类项目'">
                    <!-- 直接竞标-提供有效信息 -->
                    <template v-if="scope.row.ruleCode == 'PROVIDE_INFORMATION'">
                      <el-checkbox-group v-model="directBidding.radioList" @input="radioFn">
                        <div style="line-height: 30px; margin-top: 10px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_OFFER_FULL_TIME_BUSINESS"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '1)' }}
                              <span style="color: red"> 1%（如低于200元则按200元）</span></span
                            >信息提供人<span style="color: red"> 不是 </span
                            >商务部专职商务（招标）信息收集人员。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_OFFER_PART_TIME_BUSINESS"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '2)' }} <span style="color: red"> 100元</span></span
                            >信息提供人<span style="color: red"> 是 </span
                            >商务部专职商务（招标）信息收集人员。</el-checkbox
                          >
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 直接竞标-投标技术方案编制 -->
                    <template v-if="scope.row.ruleCode == 'CONSULT_BIDDING_SCHEME'">
                      <el-checkbox-group
                        v-model="directBidding.deptRadioDetailList"
                        @input="value => deptRadioDetailFn(value, 'directBidding')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT"
                            :disabled="disabled"
                          >
                            <span style="margin-right: 8px">{{ '1)' }}</span
                            >{{
                              '根据售前方案（针对性技术方案、咨询、DEMO等）、投标技术方案要求。'
                            }}
                          </el-checkbox>
                          <div
                            v-show="
                              directBidding.deptRadioDetailList.includes(
                                'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT'
                              )
                            "
                            style="padding-left: 1.5%"
                          >
                            <span
                              class="el-checkbox__label"
                              style="color: #606266; display: inline-block"
                              >参考<span style="color: red">P5档</span
                              >员工一般水平评估需要的工时</span
                            >
                            <el-input
                              :disabled="disabled"
                              style="width: 70px"
                              @blur="deptNameBlurFn('directBidding')"
                              type="number"
                              class="numrule"
                              v-model.number="directBidding.deptNameNumBer"
                            >
                            </el-input>
                            <span
                              class="el-checkbox__label"
                              style="color: #606266; display: inline-block"
                              >，<span style="color: red">按照200元/工时</span
                              >计算该部分绩效总额，给予售前方案和投标技术方案编制参与部门。</span
                            >
                          </div>
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN"
                            :disabled="
                              disabled ||
                              directBidding.deptRadioDetailList[0] ==
                                'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '2)' }} <span class="proportion"> 0%~20%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  directBidding.deptRadioDetailList.includes(
                                    'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >项目公开招标或邀标（技术方案起重要作用的，且技术分超过35分）或要求完善售前方案20%封顶。</el-checkbox
                          >
                        </div>

                        <div style="line-height: 30px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPEN_TENDERING"
                            :disabled="
                              disabled ||
                              directBidding.deptRadioDetailList[0] ==
                                'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPEN_TENDERING'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '3)' }} <span class="proportion"> 0%~15%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  directBidding.deptRadioDetailList.includes(
                                    'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPEN_TENDERING'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >事前沟通公开招标或邀标但以价格和商务为主的（以价格和商务为主的，且技术分不超过35分）15%封顶。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT"
                            :disabled="
                              disabled ||
                              directBidding.deptRadioDetailList[0] ==
                                'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '4)' }} <span class="proportion"> 0%~10%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  directBidding.deptRadioDetailList.includes(
                                    'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >事前沟通谈判、磋商的10%封顶。</el-checkbox
                          >
                        </div>

                        <div style="line-height: 30px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_ENQUIRY"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '5)' }} <span class="proportion"> 300元</span></span
                            >仅需提供报价方案的项目。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_GRUDGE"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '6)' }} <span class="proportion"> 0%</span></span
                            >{{ '其它不需要技术方案的不给予绩效。' }}</el-checkbox
                          >
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 直接竞标-商务跟进 -->
                    <template v-if="scope.row.ruleCode == 'BUSINESS_FOLLOW_UP'">
                      <el-checkbox-group
                        v-model="directBidding.manageRadioList"
                        @input="value => manageRadioFn(value, 'directBidding')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BUSINESS_EXTERNAL"
                            :disabled="
                              disabled ||
                              directBidding.manageRadioList[0] ==
                                'COMPETITIVE_TENDER_BUSINESS_EXTERNAL'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '1)' }} <span class="proportion"> 0%~10%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  directBidding.manageRadioList.includes(
                                    'COMPETITIVE_TENDER_BUSINESS_EXTERNAL'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >根据商务助理的工作量和成效进行评估，一般总额不超过10%。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="COMPETITIVE_TENDER_BUSINESS_NO_BUSINESS_PERSON"
                            :disabled="
                              disabled ||
                              directBidding.manageRadioList[0] ==
                                'COMPETITIVE_TENDER_BUSINESS_NO_BUSINESS_PERSON'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '2)' }} <span class="proportion"> 0%~20%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  directBidding.manageRadioList.includes(
                                    'COMPETITIVE_TENDER_BUSINESS_NO_BUSINESS_PERSON'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >商务负责人为公司商务型高管且跟进工作量比较大的，可以到20%。</el-checkbox
                          >
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 直接竞标-有效引荐 -->
                    <template v-if="scope.row.ruleCode == 'REFERRER'">
                      <el-checkbox-group
                        v-model="directBidding.referrerList"
                        @input="value => referrerFn(value, 'directBidding')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY"
                              :disabled="
                                disabled ||
                                directBidding.referrerList[0] ==
                                  'COMPETITIVE_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '1)' }} <span class="proportion"> 20%~30%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    directBidding.referrerList.includes(
                                      'COMPETITIVE_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input></span
                              >引荐到业主方关键决策人员（直接决定项目承接方）且发挥决定性作用（直接明确项目由我方承接）的。
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_CUSTOMER_REFERRER_DECISION"
                              :disabled="
                                disabled ||
                                directBidding.referrerList[0] ==
                                  'COMPETITIVE_CUSTOMER_REFERRER_DECISION'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '2)' }} <span class="proportion"> 10%~20%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    directBidding.referrerList.includes(
                                      'COMPETITIVE_CUSTOMER_REFERRER_DECISION'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input
                              ></span>
                              <span
                                >引荐到项目重要相关人员（项目采购的决策人、主要执行人）且发挥明显作用（有较为明确的合作意向）的。</span
                              >
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_CUSTOMER_REFERRER_SIGNIFICANCE"
                              :disabled="
                                disabled ||
                                directBidding.referrerList[0] ==
                                  'COMPETITIVE_CUSTOMER_REFERRER_SIGNIFICANCE'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '3)' }} <span class="proportion"> 5%~10%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    directBidding.referrerList.includes(
                                      'COMPETITIVE_CUSTOMER_REFERRER_SIGNIFICANCE'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input
                              ></span>
                              引荐到项目重要执行人员且发挥一定引荐作用（给予公司参与竞争机会但指向性不强）的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_CUSTOMER_REFERRER_ORDINARY"
                              :disabled="
                                disabled ||
                                directBidding.referrerList[0] ==
                                  'COMPETITIVE_CUSTOMER_REFERRER_ORDINARY'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '4)' }} <span class="proportion"> 0%~5%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    directBidding.referrerList.includes(
                                      'COMPETITIVE_CUSTOMER_REFERRER_ORDINARY'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input></span
                              >引荐到项目相关人员但是引荐作用不明显的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_CUSTOMER_REFERRER_GRUDG"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '5)' }} <span class="proportion"> 0%</span></span
                              ><span> 无引荐人</span></el-checkbox
                            >
                          </div>
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          1、同时引荐到多名项目相关方人员的情况，按照项目相关方作用最大人员的情况确定绩效，如其他项目相关方人员也发挥一定作用的，视情况调剂有关引荐人一定绩效。公司多名员工引荐到项目相关方人员的，根据发挥作用的情况对相应绩效进行分配。
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          2、如为III类项目，如业主方关键决策人员或项目重要执行人员未变化的，则首次发挥最重要实际效果的引荐人为历史商务关系绩效的获得者。如业主方关键决策人员和项目重要执行人员发生变化的，则按照第一款执行。
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          3、引荐人为外部合作者，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。如历史商务关系为外部合作者，则相关绩效归入公司公共经费。
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 直接竞标-负责关键沟通与组织 -->
                    <template v-if="scope.row.ruleCode == 'LEAD_COMMUNICATION'">
                      <el-checkbox-group
                        v-model="directBidding.businessManageRadioList"
                        @input="value => businessManageFn(value, 'directBidding')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_KEY_MANAGER_DECISIVE_SUPPORT"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '1)' }} <span class="proportion"> 100%</span></span
                              >获得了业主方决定性支持（对接了具体操作人或代理机构操作人并明确予以支持）的。
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_KEY_MANAGER_EFFECTIVE_INFORMATION"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '2)' }} <span class="proportion"> 50%</span></span
                              >
                              <span
                                >获取了有效信息支撑竞标（相关方提供了投标重点、参与方、报价原则）的。</span
                              >
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="COMPETITIVE_KEY_MANAGER_NAME_BUSINESS_MANAGER"
                              :disabled="
                                disabled ||
                                directBidding.businessManageRadioList[0] ==
                                  'COMPETITIVE_KEY_MANAGER_NAME_BUSINESS_MANAGER'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '3)' }} <span class="proportion"> 5%~30%</span
                                ><el-input
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  v-model="directBidding.managerNumBer"
                                  @blur="managerBlurFn('directBidding')"
                                  :disabled="disabled"
                                  v-show="
                                    directBidding.businessManageRadioList.includes(
                                      'COMPETITIVE_KEY_MANAGER_NAME_BUSINESS_MANAGER'
                                    )
                                  "
                                ></el-input
                              ></span>
                              名义商务负责人一般按照5%给予绩效，如需要名义商务负责人投入较大精力进行跟进的外部商务合作项目，由商务审核人根据跟进工作量在30%范围确定绩效。</el-checkbox
                            >
                          </div>
                        </div>
                      </el-checkbox-group>
                    </template>
                  </template>

                  <!-- 有事前沟通新客户项目 -->
                  <template v-if="scope.row.businessType === '有事前沟通新客户项目'">
                    <!-- 新客户-提供有效信息 -->
                    <template v-if="scope.row.ruleCode == 'PROVIDE_INFORMATION'">
                      <div class="deptClass">
                        <span>{{ '1)' }} </span>
                        {{ '是否为项目预算批准前获取的信息。' }}
                      </div>
                      <div style="padding-left: 20px">
                        <el-checkbox-group
                          v-model="newCustomers.informationProviderOneList"
                          @input="value => informationProviderFn(value, 'One', 'newCustomers')"
                        >
                          <div style="line-height: 30px; margin-left: 20px">
                            <el-checkbox label="NEW_CUSTOMER_RATIFY_YES" :disabled="disabled">
                              <span style="margin-right: 8px"
                                >{{ '1-1)' }} <span style="color: red"> 1%</span></span
                              >是
                            </el-checkbox>
                            <el-checkbox label="NEW_CUSTOMER_RATIFY_NO" :disabled="disabled">
                              <span style="margin-right: 8px"
                                >{{ '1-2)' }} <span style="color: red"> 0.5%</span></span
                              >否
                            </el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </div>
                      <div class="deptClass">
                        <span>{{ '2)' }} </span>
                        {{ '是否包含项目牵头人、项目金额、项目选取方式等项目信息。' }}
                      </div>
                      <div style="padding-left: 20px">
                        <el-checkbox-group
                          v-model="newCustomers.informationProviderTwoList"
                          @input="value => informationProviderFn(value, 'Two', 'newCustomers')"
                        >
                          <div style="line-height: 30px; margin-left: 20px">
                            <el-checkbox
                              label="NEW_CUSTOMER_PROJECT_ABUNDANT_YES"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '2-1)' }} <span style="color: red"> 1%</span></span
                              >是
                            </el-checkbox>
                            <el-checkbox
                              label="NEW_CUSTOMER_PROJECT_ABUNDANT_NO"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '2-2)' }} <span style="color: red"> 0.5%</span></span
                              >否
                            </el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </div>
                      <div class="deptClass">
                        <span>{{ '3)' }} </span>
                        {{ '项目来源为市级、省级或其他' }}
                      </div>
                      <div style="padding-left: 20px">
                        <el-checkbox-group
                          v-model="newCustomers.informationProviderThreeList"
                          @input="value => informationProviderFn(value, 'Three', 'newCustomers')"
                        >
                          <div style="line-height: 30px; margin-left: 20px">
                            <el-checkbox
                              label="NEW_CUSTOMER_PROJECT_PRODUCE_CITY_LEVEL"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '3-1)' }} <span style="color: red"> 0.5%</span></span
                              >市级政府部门公开部署。
                            </el-checkbox>
                            <el-checkbox
                              label="NEW_CUSTOMER_PROJECT_PRODUCE_PROVINCIAL_LEVEL"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '3-2' }} <span style="color: red"> 0%</span></span
                              >省级以上政府部门公开部署，不给予绩效。
                            </el-checkbox>
                            <el-checkbox
                              label="NEW_CUSTOMER_PROJECT_PRODUCE_OTHER"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '3-3)' }} <span style="color: red"> 1%</span></span
                              >其它。
                            </el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </div>
                      <div class="el-checkbox__label deptClass">如低于200元则按照200元计算</div>
                    </template>
                    <!-- 新客户-投标技术方案编制 -->
                    <template v-if="scope.row.ruleCode == 'CONSULT_BIDDING_SCHEME'">
                      <el-checkbox-group
                        v-model="newCustomers.deptRadioDetailList"
                        @input="value => deptRadioDetailFn(value, 'newCustomers')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT"
                            :disabled="disabled"
                          >
                            <span style="margin-right: 8px">{{ '1)' }}</span
                            >{{
                              '根据售前方案（针对性技术方案、咨询、DEMO等）、投标技术方案要求。'
                            }}
                          </el-checkbox>
                          <div
                            v-show="
                              newCustomers.deptRadioDetailList.includes(
                                'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT'
                              )
                            "
                            style="padding-left: 1.5%"
                          >
                            <span
                              class="el-checkbox__label"
                              style="color: #606266; display: inline-block"
                              >参考<span style="color: red">P5档</span
                              >员工一般水平评估需要的工时</span
                            >
                            <el-input
                              style="width: 70px"
                              :disabled="disabled"
                              @blur="deptNameBlurFn('newCustomers')"
                              type="number"
                              class="numrule"
                              v-model.number="newCustomers.deptNameNumBer"
                            >
                            </el-input>
                            <span
                              class="el-checkbox__label"
                              style="color: #606266; display: inline-block"
                              >，<span style="color: red">按照200元/工时</span
                              >计算该部分绩效总额，给予售前方案和投标技术方案编制参与部门。</span
                            >
                          </div>
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN"
                            :disabled="
                              disabled ||
                              newCustomers.deptRadioDetailList[0] ==
                                'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '2)' }} <span class="proportion"> 0%~20%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  newCustomers.deptRadioDetailList.includes(
                                    'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >项目公开招标或邀标（技术方案起重要作用的，且技术分超过35分）或要求完善售前方案20%封顶。</el-checkbox
                          >
                        </div>

                        <div style="line-height: 30px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING"
                            :disabled="
                              disabled ||
                              newCustomers.deptRadioDetailList[0] ==
                                'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '3)' }} <span class="proportion"> 0%~15%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  newCustomers.deptRadioDetailList.includes(
                                    'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >事前沟通公开招标或邀标但以价格和商务为主的（以价格和商务为主的，且技术分不超过35分）15%封顶。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT"
                            :disabled="
                              disabled ||
                              newCustomers.deptRadioDetailList[0] ==
                                'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '4)' }} <span class="proportion"> 0%~10%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  newCustomers.deptRadioDetailList.includes(
                                    'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >事前沟通谈判、磋商的10%封顶。</el-checkbox
                          >
                        </div>

                        <div style="line-height: 30px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_ENQUIRY"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '5)' }} <span class="proportion"> 300元</span></span
                            >仅需提供报价方案的项目。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_GRUDGE"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '6)' }} <span class="proportion"> 0%</span></span
                            >{{ '其它不需要技术方案的不给予绩效。' }}</el-checkbox
                          >
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 新客户-商务跟进 -->
                    <template v-if="scope.row.ruleCode == 'BUSINESS_FOLLOW_UP'">
                      <el-checkbox-group
                        v-model="newCustomers.manageRadioList"
                        @input="value => manageRadioFn(value, 'newCustomers')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BUSINESS_EXTERNAL"
                            :disabled="
                              disabled ||
                              newCustomers.manageRadioList[0] == 'NEW_CUSTOMER_BUSINESS_EXTERNAL'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '1)' }} <span class="proportion"> 0%~10%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  newCustomers.manageRadioList.includes(
                                    'NEW_CUSTOMER_BUSINESS_EXTERNAL'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >根据商务助理的工作量和成效进行评估，一般总额不超过10%。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="NEW_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON"
                            :disabled="
                              disabled ||
                              newCustomers.manageRadioList[0] ==
                                'NEW_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '2)' }} <span class="proportion"> 0%~20%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  newCustomers.manageRadioList.includes(
                                    'NEW_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >商务负责人为公司商务型高管且跟进工作量比较大的，可以到20%。</el-checkbox
                          >
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 新客户-有效引荐 -->
                    <template v-if="scope.row.ruleCode == 'REFERRER'">
                      <el-checkbox-group
                        v-model="newCustomers.referrerList"
                        @input="value => referrerFn(value, 'newCustomers')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <div>
                            <el-checkbox
                              label="NEW_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY"
                              :disabled="
                                disabled ||
                                newCustomers.referrerList[0] ==
                                  'NEW_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '1)' }} <span class="proportion"> 20%~30%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    newCustomers.referrerList.includes(
                                      'NEW_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input></span
                              >引荐到业主方关键决策人员（直接决定项目承接方）且发挥决定性作用（直接明确项目由我方承接）的。
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="NEW_CUSTOMER_REFERRER_DECISION"
                              :disabled="
                                disabled ||
                                newCustomers.referrerList[0] == 'NEW_CUSTOMER_REFERRER_DECISION'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '2)' }} <span class="proportion"> 10%~20%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    newCustomers.referrerList.includes(
                                      'NEW_CUSTOMER_REFERRER_DECISION'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input
                              ></span>
                              <span
                                >引荐到项目重要相关人员（项目采购的决策人、主要执行人）且发挥明显作用（有较为明确的合作意向）的。</span
                              >
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="NEW_CUSTOMER_REFERRER_SIGNIFICANCE"
                              :disabled="
                                disabled ||
                                newCustomers.referrerList[0] == 'NEW_CUSTOMER_REFERRER_SIGNIFICANCE'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '3)' }} <span class="proportion"> 5%~10%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    newCustomers.referrerList.includes(
                                      'NEW_CUSTOMER_REFERRER_SIGNIFICANCE'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input
                              ></span>
                              引荐到项目重要执行人员且发挥一定引荐作用（给予公司参与竞争机会但指向性不强）的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="NEW_CUSTOMER_REFERRER_ORDINARY"
                              :disabled="
                                disabled ||
                                newCustomers.referrerList[0] == 'NEW_CUSTOMER_REFERRER_ORDINARY'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '4)' }} <span class="proportion"> 0%~5%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    newCustomers.referrerList.includes(
                                      'NEW_CUSTOMER_REFERRER_ORDINARY'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input></span
                              >引荐到项目相关人员但是引荐作用不明显的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox label="NEW_CUSTOMER_REFERRER_GRUDG" :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '5)' }} <span class="proportion"> 0%</span></span
                              ><span> 无引荐人</span></el-checkbox
                            >
                          </div>
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          1、同时引荐到多名项目相关方人员的情况，按照项目相关方作用最大人员的情况确定绩效，如其他项目相关方人员也发挥一定作用的，视情况调剂有关引荐人一定绩效。公司多名员工引荐到项目相关方人员的，根据发挥作用的情况对相应绩效进行分配。
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          2、如为III类项目，如业主方关键决策人员或项目重要执行人员未变化的，则首次发挥最重要实际效果的引荐人为历史商务关系绩效的获得者。如业主方关键决策人员和项目重要执行人员发生变化的，则按照第一款执行。
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          3、引荐人为外部合作者，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。如历史商务关系为外部合作者，则相关绩效归入公司公共经费。
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 新客户-负责关键沟通与组织 -->
                    <template v-if="scope.row.ruleCode == 'LEAD_COMMUNICATION'">
                      <el-checkbox-group
                        v-model="newCustomers.businessManageRadioList"
                        @input="value => businessManageFn(value, 'newCustomers')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <div>
                            <el-checkbox label="NEW_KEY_MANAGER_CERTAINTY" :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '1)' }} <span class="proportion"> 100%</span></span
                              >确定性选择对象（直接选定、主导招标文件、对接代理机构操作人并明确意图）的。
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="NEW_KEY_MANAGER_COMPETITIVENESS"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '2)' }} <span class="proportion"> 75%</span></span
                              >
                              <span
                                >竞争性选择对象（招标文件部分采纳条件、明确有限对象中选择）的。</span
                              >
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="NEW_KEY_MANAGER_EFFECTIVE_INFORMATION"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '3)' }} <span class="proportion"> 50%</span></span
                              >提供有效信息支撑竞标的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="NEW_KEY_MANAGER_NAME_BUSINESS_MANAGER"
                              :disabled="
                                disabled ||
                                newCustomers.businessManageRadioList[0] ==
                                  'NEW_KEY_MANAGER_NAME_BUSINESS_MANAGER'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '4)' }} <span class="proportion"> 5%~30%</span
                                ><el-input
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  v-model="newCustomers.managerNumBer"
                                  @blur="managerBlurFn('newCustomers')"
                                  :disabled="disabled"
                                  v-show="
                                    newCustomers.businessManageRadioList.includes(
                                      'NEW_KEY_MANAGER_NAME_BUSINESS_MANAGER'
                                    )
                                  "
                                ></el-input
                              ></span>
                              名义商务负责人一般按照5%给予绩效，如需要名义商务负责人投入较大精力进行跟进的外部商务合作项目，由商务审核人根据跟进工作量在30%范围确定绩效。</el-checkbox
                            >
                          </div>
                        </div>
                      </el-checkbox-group>
                    </template>
                  </template>

                  <!-- 有事前沟通老客户项目 -->
                  <template v-if="scope.row.businessType === '有事前沟通老客户项目'">
                    <!-- 老客户-提供有效信息 -->
                    <template v-if="scope.row.ruleCode == 'PROVIDE_INFORMATION'">
                      <div class="deptClass">
                        <span>{{ '1)' }} </span>
                        {{ '是否为项目预算批准前获取的信息。' }}
                      </div>
                      <div style="padding-left: 20px">
                        <el-checkbox-group
                          v-model="oldCustomer.informationProviderOneList"
                          @input="value => informationProviderFn(value, 'One', 'oldCustomer')"
                        >
                          <div style="line-height: 30px; margin-left: 20px">
                            <el-checkbox label="OLD_CUSTOMER_RATIFY_YES" :disabled="disabled">
                              <span style="margin-right: 8px"
                                >{{ '1-1)' }} <span style="color: red"> 1%</span></span
                              >是
                            </el-checkbox>
                            <el-checkbox label="OLD_CUSTOMER_RATIFY_NO" :disabled="disabled">
                              <span style="margin-right: 8px"
                                >{{ '1-2)' }} <span style="color: red"> 0.5%</span></span
                              >否
                            </el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </div>
                      <div class="deptClass">
                        <span>{{ '2)' }} </span>
                        {{ '是否包含项目牵头人、项目金额、项目选取方式等项目信息。' }}
                      </div>
                      <div style="padding-left: 20px">
                        <el-checkbox-group
                          v-model="oldCustomer.informationProviderTwoList"
                          @input="value => informationProviderFn(value, 'Two', 'oldCustomer')"
                        >
                          <div style="line-height: 30px; margin-left: 20px">
                            <el-checkbox
                              label="OLD_CUSTOMER_PROJECT_ABUNDANT_YES"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '2-1)' }} <span style="color: red"> 1%</span></span
                              >是
                            </el-checkbox>
                            <el-checkbox
                              label="OLD_CUSTOMER_PROJECT_ABUNDANT_NO"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '2-2)' }} <span style="color: red"> 0.5%</span></span
                              >否
                            </el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </div>
                      <div class="deptClass">
                        <span>{{ '3)' }} </span>
                        {{ '项目来源为市级、省级或其他' }}
                      </div>
                      <div style="padding-left: 20px">
                        <el-checkbox-group
                          v-model="oldCustomer.informationProviderThreeList"
                          @input="value => informationProviderFn(value, 'Three', 'oldCustomer')"
                        >
                          <div style="line-height: 30px; margin-left: 20px">
                            <el-checkbox
                              label="OLD_CUSTOMER_PROJECT_PRODUCE_CITY_LEVEL"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '3-1)' }} <span style="color: red"> 0.5%</span></span
                              >市级政府部门公开部署。
                            </el-checkbox>
                            <el-checkbox
                              label="OLD_CUSTOMER_PROJECT_PRODUCE_PROVINCIAL_LEVEL"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '3-2)' }} <span style="color: red"> 0%</span></span
                              >省级以上政府部门公开部署，不给予绩效。
                            </el-checkbox>
                            <el-checkbox
                              label="OLD_CUSTOMER_PROJECT_PRODUCE_OTHER"
                              :disabled="disabled"
                            >
                              <span style="margin-right: 8px"
                                >{{ '3-3)' }} <span style="color: red"> 1%</span></span
                              >其它
                            </el-checkbox>
                          </div>
                        </el-checkbox-group>
                      </div>
                      <div class="el-checkbox__label deptClass">如低于200元则按照200元计算。</div>
                    </template>
                    <!-- 老客户-投标技术方案编制 -->
                    <template v-if="scope.row.ruleCode == 'CONSULT_BIDDING_SCHEME'">
                      <el-alert
                        v-show="
                          oldCustomer.deptRadioDetailList.includes(
                            'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT'
                          )
                        "
                        style="width: 530px; margin-top: 10px"
                        :closable="false"
                        title="绩效金额核算时，商务团队费用会先减去该金额，再根据对应的比例分配绩效金额"
                        type="error"
                      >
                      </el-alert>
                      <el-checkbox-group
                        v-model="oldCustomer.deptRadioDetailList"
                        @input="value => deptRadioDetailFn(value, 'oldCustomer')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT"
                            :disabled="disabled"
                          >
                            <span style="margin-right: 8px">{{ '1)' }}</span
                            >{{
                              '根据售前方案（针对性技术方案、咨询、DEMO等）、投标技术方案要求。'
                            }}
                          </el-checkbox>

                          <div
                            v-show="
                              oldCustomer.deptRadioDetailList.includes(
                                'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT'
                              )
                            "
                            style="padding-left: 1.5%"
                          >
                            <span
                              class="el-checkbox__label"
                              style="color: #606266; display: inline-block"
                              >参考<span style="color: red">P5档</span
                              >员工一般水平评估需要的工时</span
                            >
                            <el-input
                              style="width: 70px"
                              :disabled="disabled"
                              @blur="deptNameBlurFn('oldCustomer')"
                              type="number"
                              class="numrule"
                              v-model.number="oldCustomer.deptNameNumBer"
                            >
                            </el-input>
                            <span
                              class="el-checkbox__label"
                              style="color: #606266; display: inline-block"
                              >，<span style="color: red">按照200元/工时</span
                              >计算该部分绩效总额，给予售前方案和投标技术方案编制参与部门。</span
                            >
                          </div>
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN"
                            :disabled="
                              disabled ||
                              oldCustomer.deptRadioDetailList[0] ==
                                'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '2)' }} <span class="proportion"> 0%~20%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  oldCustomer.deptRadioDetailList.includes(
                                    'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >项目公开招标或邀标（技术方案起重要作用的，且技术分超过35分）或要求完善售前方案20%封顶。</el-checkbox
                          >
                        </div>

                        <div style="line-height: 30px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING"
                            :disabled="
                              disabled ||
                              oldCustomer.deptRadioDetailList[0] ==
                                'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '3)' }} <span class="proportion"> 0%~15%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  oldCustomer.deptRadioDetailList.includes(
                                    'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >事前沟通公开招标或邀标但以价格和商务为主的（以价格和商务为主的，且技术分不超过35分）15%封顶。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT"
                            :disabled="
                              disabled ||
                              oldCustomer.deptRadioDetailList[0] ==
                                'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '4)' }} <span class="proportion"> 0%~10%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  oldCustomer.deptRadioDetailList.includes(
                                    'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input></span
                            >事前沟通谈判、磋商的10%封顶。</el-checkbox
                          >
                        </div>

                        <div style="line-height: 30px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_ENQUIRY"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '5)' }} <span class="proportion"> 300元</span></span
                            >仅需提供报价方案的项目。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_GRUDGE"
                            :disabled="disabled"
                            ><span style="margin-right: 8px"
                              >{{ '6)' }} <span class="proportion"> 0%</span></span
                            >{{ '其它不需要技术方案的不给予绩效。' }}</el-checkbox
                          >
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 老客户-商务跟进 -->
                    <template v-if="scope.row.ruleCode == 'BUSINESS_FOLLOW_UP'">
                      <el-checkbox-group
                        v-model="oldCustomer.manageRadioList"
                        @input="value => manageRadioFn(value, 'oldCustomer')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BUSINESS_EXTERNAL"
                            :disabled="
                              disabled ||
                              oldCustomer.manageRadioList[0] == 'OLD_CUSTOMER_BUSINESS_EXTERNAL'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '1)' }} <span class="proportion"> 0%~10%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  oldCustomer.manageRadioList.includes(
                                    'OLD_CUSTOMER_BUSINESS_EXTERNAL'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input> </span
                            >根据商务助理的工作量和成效进行评估，一般总额不超过10%。</el-checkbox
                          >
                        </div>
                        <div style="line-height: 30px">
                          <el-checkbox
                            label="OLD_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON"
                            :disabled="
                              disabled ||
                              oldCustomer.manageRadioList[0] ==
                                'OLD_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON'
                            "
                            ><span style="margin-right: 8px"
                              >{{ '2)' }} <span class="proportion"> 0%~20%</span>
                              <el-input
                                @blur="ratioBlurFn(scope.row)"
                                :disabled="disabled"
                                style="width: 70px; margin-left: 8px"
                                type="number"
                                placeholder="0"
                                class="numrule Percentage"
                                @input="$forceUpdate()"
                                v-show="
                                  oldCustomer.manageRadioList.includes(
                                    'OLD_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON'
                                  )
                                "
                                v-model="scope.row.ratio"
                              ></el-input> </span
                            >商务负责人为公司商务型高管且跟进工作量比较大的，可以到20%。</el-checkbox
                          >
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 老客户-有效引荐（历史商务关系） -->
                    <template v-if="scope.row.ruleCode == 'HISTORICAL_BUSINESS_REFERRER'">
                      <el-checkbox-group
                        v-model="oldCustomer.referrerList"
                        @input="value => referrerFn(value, 'oldCustomer')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_BUSINESS_INTERMEDIARY"
                              :disabled="
                                disabled ||
                                oldCustomer.referrerList[0] ==
                                  'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_BUSINESS_INTERMEDIARY'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '1)' }} <span class="proportion"> 20%~30%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    oldCustomer.referrerList.includes(
                                      'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_BUSINESS_INTERMEDIARY'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input> </span
                              >引荐到业主方关键决策人员（直接决定项目承接方）且发挥决定性作用（直接明确项目由我方承接）的。
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_DECISION"
                              :disabled="
                                disabled ||
                                oldCustomer.referrerList[0] ==
                                  'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_DECISION'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '2)' }} <span class="proportion"> 10%~20%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    oldCustomer.referrerList.includes(
                                      'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_DECISION'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input>
                              </span>
                              <span
                                >引荐到项目重要相关人员（项目采购的决策人、主要执行人）且发挥明显作用（有较为明确的合作意向）的。</span
                              >
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_SIGNIFICANCE"
                              :disabled="
                                disabled ||
                                oldCustomer.referrerList[0] ==
                                  'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_SIGNIFICANCE'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '3)' }} <span class="proportion"> 5%~10%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    oldCustomer.referrerList.includes(
                                      'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_SIGNIFICANCE'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input>
                              </span>
                              引荐到项目重要执行人员且发挥一定引荐作用（给予公司参与竞争机会但指向性不强）的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_ORDINARY"
                              :disabled="
                                disabled ||
                                oldCustomer.referrerList[0] ==
                                  'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_ORDINARY'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '4)' }} <span class="proportion"> 0%~5%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row)"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    oldCustomer.referrerList.includes(
                                      'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_ORDINARY'
                                    )
                                  "
                                  v-model="scope.row.ratio"
                                ></el-input> </span
                              >引荐到项目相关人员但是引荐作用不明显的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_GRUDG"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '5)' }} <span class="proportion"> 0%</span></span
                              ><span> 无引荐人</span></el-checkbox
                            >
                          </div>
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          1、同时引荐到多名项目相关方人员的情况，按照项目相关方作用最大人员的情况确定绩效，如其他项目相关方人员也发挥一定作用的，视情况调剂有关引荐人一定绩效。公司多名员工引荐到项目相关方人员的，根据发挥作用的情况对相应绩效进行分配。
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          2、如为III类项目，如业主方关键决策人员或项目重要执行人员未变化的，则首次发挥最重要实际效果的引荐人为历史商务关系绩效的获得者。如业主方关键决策人员和项目重要执行人员发生变化的，则按照第一款执行。
                        </div>
                        <div class="el-checkbox__label" style="line-height: 30px">
                          3、引荐人为外部合作者，该商务绩效占比仅用于计算，不作为外部合作者费用的直接标准。如历史商务关系为外部合作者，则相关绩效归入公司公共经费。
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 老客户-上一项目实施团队 -->
                    <template v-if="scope.row.ruleCode == 'PROJECT_IMPLEMENTATION'">
                      <el-checkbox-group
                        v-model="oldCustomer.previousProjectList"
                        @input="value => previousProjectFn(value, 'oldCustomer')"
                      >
                        <div class="deptClass">
                          {{ '驻场项目服务单位委托的新项目' }}
                        </div>
                        <div style="line-height: 30px; padding-left: 20px">
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_IMPLEMENTATION_TEAM_CONCLUSIVE"
                              :disabled="
                                disabled ||
                                oldCustomer.previousProjectList[0] ==
                                  'OLD_CUSTOMER_IMPLEMENTATION_TEAM_CONCLUSIVE'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '1)'
                                }}<span style="color: red; margin-left: 10px"> 20%~30%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row, 'PROJECT_IMPLEMENTATION')"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    oldCustomer.previousProjectList.includes(
                                      'OLD_CUSTOMER_IMPLEMENTATION_TEAM_CONCLUSIVE'
                                    )
                                  "
                                  v-model="scope.row.temporaryRatio"
                                ></el-input> </span
                              >驻场单位通过驻场人员向公司提供项目信息的，驻场团队在获得提供有效项目信息费用的基础上，分配商务负责人绩效部分的20%~30%给到驻场团队。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_IMPLEMENTATION_TEAM_POSITIVE_ATTITUDE"
                              :disabled="
                                disabled ||
                                oldCustomer.previousProjectList[0] ==
                                  'OLD_CUSTOMER_IMPLEMENTATION_TEAM_POSITIVE_ATTITUDE'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '2)'
                                }}<span style="color: red; margin-left: 10px"> 10%~20%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row, 'PROJECT_IMPLEMENTATION')"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    oldCustomer.previousProjectList.includes(
                                      'OLD_CUSTOMER_IMPLEMENTATION_TEAM_POSITIVE_ATTITUDE'
                                    )
                                  "
                                  v-model="scope.row.temporaryRatio"
                                ></el-input>
                              </span>
                              <!-- 驻场单位与<span style="color: red"> 公司非驻场团队 </span
                              >沟通项目信息，
                              <span style="color: red"> 且持肯定态度 </span>的，分配牵头沟通费用的10%~20%绩效。 -->
                              驻场单位直接与公司非驻场人员联系并提供项目信息，同时对驻场团队持较为肯定态度的，分配牵头沟通费用的10%~20%绩效。
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_IMPLEMENTATION_TEAM_NOT_NEAGTIVE_ATTITUDE"
                              :disabled="
                                disabled ||
                                oldCustomer.previousProjectList[0] ==
                                  'OLD_CUSTOMER_IMPLEMENTATION_TEAM_NOT_NEAGTIVE_ATTITUDE'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '3)'
                                }}<span style="color: red; margin-left: 10px"> 0%~10%</span>
                                <el-input
                                  @blur="ratioBlurFn(scope.row, 'PROJECT_IMPLEMENTATION')"
                                  :disabled="disabled"
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  placeholder="0"
                                  class="numrule Percentage"
                                  @input="$forceUpdate()"
                                  v-show="
                                    oldCustomer.previousProjectList.includes(
                                      'OLD_CUSTOMER_IMPLEMENTATION_TEAM_NOT_NEAGTIVE_ATTITUDE'
                                    )
                                  "
                                  v-model="scope.row.temporaryRatio"
                                ></el-input>
                              </span>
                              驻场单位对公司驻场团队<span style="color: red"> 不持负面态度 </span
                              >的，分配牵头沟通费用的0%~10%绩效。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_CUSTOMER_IMPLEMENTATION_TEAM_NEAGTIVE_ATTITUDE"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '4)'
                                }}<span style="color: red; margin-left: 10px"> 0%</span></span
                              >
                              驻场单位对公司驻场团队<span style="color: red"> 持负面态度 </span
                              >。</el-checkbox
                            >
                          </div>
                        </div>
                        <div class="deptClass">
                          {{ '非驻场项目服务单位委托的新项目' }}
                        </div>
                        <div style="line-height: 30px; padding-left: 20px">
                          <div>
                            <el-checkbox
                              label="OLD_SERVICE_UNIT_EFFECTIVE_INFORMATION"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '1)'
                                }}<span style="color: red; margin-left: 10px"> 20%</span></span
                              >项目单位通过实施人员向公司提供项目信息的，实施团队在获得提供有效项目信息费用的基础上，分配实施团队的10%和生产部门商务负责人绩效的10%。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_SERVICE_UNIT_NON_IMPLEMENTATION_AFFIRM"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '2)'
                                }}<span style="color: red; margin-left: 10px"> 10%</span></span
                              >
                              服务单位与<span style="color: red"> 公司非实施人员 </span
                              >联系并提供项目信息，同时对
                              <span style="color: red"> 上一项目实施团队持肯定态度的 </span
                              >，分配生产部门商务负责人绩效的10%。
                            </el-checkbox>
                          </div>
                        </div>
                      </el-checkbox-group>
                    </template>
                    <!-- 老客户-负责关键沟通与组织 -->
                    <template v-if="scope.row.ruleCode == 'LEAD_COMMUNICATION'">
                      <el-checkbox-group
                        v-model="oldCustomer.businessManageRadioList"
                        @input="value => businessManageFn(value, 'oldCustomer')"
                      >
                        <div style="line-height: 30px; margin-top: 10px">
                          <div>
                            <el-checkbox label="OLD_KEY_MANAGER_CERTAINTY" :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '1)' }} <span class="proportion"> 100%</span></span
                              >确定性选择对象（直接选定、主导招标文件、对接代理机构操作人并明确意图）的。
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_KEY_MANAGER_COMPETITIVENESS"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '2)' }} <span class="proportion"> 75%</span></span
                              >
                              <span
                                >竞争性选择对象（招标文件部分采纳条件、明确有限对象中选择）的。</span
                              >
                            </el-checkbox>
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_KEY_MANAGER_EFFECTIVE_INFORMATION"
                              :disabled="disabled"
                              ><span style="margin-right: 8px"
                                >{{ '3)' }} <span class="proportion"> 50%</span></span
                              >提供有效信息支撑竞标的。</el-checkbox
                            >
                          </div>
                          <div>
                            <el-checkbox
                              label="OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER"
                              :disabled="
                                disabled ||
                                oldCustomer.businessManageRadioList[0] ==
                                  'OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER'
                              "
                              ><span style="margin-right: 8px"
                                >{{ '4)' }} <span class="proportion"> 5%~30%</span
                                ><el-input
                                  style="width: 70px; margin-left: 8px"
                                  type="number"
                                  class="numrule Percentage"
                                  v-model="oldCustomer.managerNumBer"
                                  @blur="managerBlurFn('oldCustomer')"
                                  :disabled="disabled"
                                  placeholder="0"
                                  v-show="
                                    oldCustomer.businessManageRadioList.includes(
                                      'OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER'
                                    )
                                  "
                                ></el-input
                              ></span>
                              名义商务负责人一般按照5%给予绩效，如需要名义商务负责人投入较大精力进行跟进的外部商务合作项目，由商务审核人根据跟进工作量在30%范围确定绩效。</el-checkbox
                            >
                          </div>
                        </div>
                      </el-checkbox-group>
                    </template>
                  </template>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column align="center" type="index" label="序号" width="50"> </el-table-column> -->
            <el-table-column
              prop="workContent"
              label="工作内容"
              :show-overflow-tooltip="false"
              width="165"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="participatingDistributor"
              :show-overflow-tooltip="false"
              label="参与角色"
              width="130"
            >
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              prop="ratioRule"
              label="商务占比规则"
              width="130"
            >
            </el-table-column>

            <!-- 无事前 -->
            <template v-if="Index === 0">
              <el-table-column
                align="center"
                :show-overflow-tooltip="false"
                label="商务占比"
                width="100"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.contentType === 'MONEY_TYPE'">
                    <div class="first">{{ scope.row.money }}</div>
                  </template>
                  <template v-else>
                    <div class="percentage">
                      {{ scope.row.ratio ? scope.row.ratio : 0 }}
                    </div>
                  </template>
                </template>
              </el-table-column>
            </template>
            <!-- 新客户 -->
            <template v-else-if="Index === 1">
              <el-table-column
                align="center"
                :show-overflow-tooltip="false"
                label="商务占比"
                width="100"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.contentType === 'MONEY_TYPE'">
                    <div class="first">{{ scope.row.money }}</div>
                  </template>
                  <template v-else>
                    <div class="percentage">
                      {{ scope.row.ratio ? scope.row.ratio : 0 }}
                    </div>
                  </template>
                </template>
              </el-table-column>
            </template>
            <!-- 老客户 -->
            <template v-else>
              <el-table-column
                align="center"
                :show-overflow-tooltip="false"
                label="商务占比"
                width="100"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.contentType === 'MONEY_TYPE'">
                    <div class="first">{{ scope.row.money }}</div>
                  </template>
                  <template v-else>
                    <div class="percentage">
                      {{ scope.row.ratio ? scope.row.ratio : 0 }}
                    </div>
                  </template>
                </template>
              </el-table-column>
            </template>

            <el-table-column align="center" :show-overflow-tooltip="false" label="事项说明">
              <template slot-scope="scope">
                <el-input
                  :disabled="disabled"
                  v-model="scope.row.instructionMatters"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 99 }"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              label="人员占比"
              width="350"
            >
              <template slot-scope="scope">
                <i
                  v-if="
                    scope.row.ruleCode !== 'LEAD_COMMUNICATION' &&
                    scope.row.ruleCode !== 'OTHER' &&
                    !disabled
                  "
                  @click="addUserFn(scope)"
                  class="el-icon-circle-plus-outline text_Addt_Bgc"
                >
                </i>
                <template v-for="(i, j) in scope.row.userAssignment">
                  <template v-if="i.ruleCode === scope.row.ruleCode">
                    <div :key="j">
                      <div class="userProportion user-main">
                        <template v-if="i.businessManagerSign === 'HAVE'">
                          <span class="user-main"
                            >经理:
                            <template v-if="i.userName.length > 6">
                              <el-tooltip
                                class="item"
                                effect="dark"
                                :content="i.userName"
                                placement="top"
                              >
                                <el-tag type="warning" class="user-container">{{
                                  i.userName
                                }}</el-tag>
                              </el-tooltip> </template
                            ><el-tag v-else type="warning" class="user-container">{{
                              i.userName
                            }}</el-tag></span
                          ><span class="user-main"
                            >占比:
                            <template v-if="scope.row.contentType === 'MONEY_TYPE'"
                              ><el-input
                                :disabled="disabled || scope.row.money <= 0"
                                style="width: 120px"
                                class="userFirst"
                                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                v-model="i.userMoney"
                              >
                              </el-input
                            ></template>
                            <template v-else
                              ><el-input
                                :disabled="disabled || scope.row.ratio <= 0"
                                style="width: 120px"
                                class="userPercentage"
                                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                v-model="i.userRatio"
                              >
                              </el-input
                            ></template>
                          </span>
                        </template>
                        <template v-else>
                          <template v-if="i.userType === 'MEMBER'">
                            <span class="user-main"
                              >成员:
                              <template v-if="i.userName.length > 6">
                                <el-tooltip
                                  class="item"
                                  effect="dark"
                                  :content="i.userName"
                                  placement="top"
                                >
                                  <el-tag class="user-container">{{ i.userName }}</el-tag>
                                </el-tooltip> </template
                              ><el-tag v-else class="user-container">{{ i.userName }}</el-tag></span
                            ><span class="user-main"
                              >占比:
                              <template v-if="scope.row.contentType === 'MONEY_TYPE'">
                                <el-input
                                  :disabled="disabled || scope.row.money <= 0"
                                  style="width: 120px"
                                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                  v-model="i.userMoney"
                                  class="userFirst"
                                ></el-input
                              ></template>
                              <template v-else
                                ><el-input
                                  :disabled="disabled || scope.row.ratio <= 0"
                                  style="width: 120px"
                                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                  v-model="i.userRatio"
                                  class="userPercentage"
                                ></el-input
                              ></template>
                            </span>
                          </template>
                          <template v-if="i.userType === 'DEPT'">
                            <span class="user-main"
                              >部门:
                              <template v-if="i.deptName.length > 6">
                                <el-tooltip
                                  class="item"
                                  effect="dark"
                                  :content="i.deptName"
                                  placement="top"
                                >
                                  <el-tag type="success" class="user-container">{{
                                    i.deptName
                                  }}</el-tag>
                                </el-tooltip>
                              </template>
                              <el-tag v-else type="success" class="user-container">{{
                                i.deptName
                              }}</el-tag> </span
                            ><span class="user-main"
                              >占比:
                              <template v-if="scope.row.contentType === 'MONEY_TYPE'"
                                ><el-input
                                  :disabled="disabled || scope.row.money <= 0"
                                  style="width: 120px"
                                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                  v-model="i.userMoney"
                                  class="userFirst"
                                ></el-input
                              ></template>
                              <template v-else
                                ><el-input
                                  :disabled="disabled || scope.row.ratio <= 0"
                                  style="width: 120px"
                                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                  v-model="i.userRatio"
                                  class="userPercentage"
                                ></el-input
                              ></template>
                            </span>
                          </template>
                        </template>
                        <i
                          v-if="
                            scope.row.ruleCode !== 'LEAD_COMMUNICATION' &&
                            scope.row.ruleCode !== 'OTHER' &&
                            !disabled
                          "
                          @click="removeUserFn(j, scope.row, i)"
                          class="el-icon-circle-close text_Remove_Bgc"
                        ></i>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="scope.row.ruleCode === 'PROVIDE_INFORMATION'">
                  <template
                    v-if="
                      (iSnum1 != Number(scope.row.ratio) &&
                        scope.row.contentType === 'RATIO_TYPE') ||
                      (iSnum1 != Number(scope.row.money) && scope.row.contentType === 'MONEY_TYPE')
                    "
                  >
                    <el-alert
                      ref="alert_1_Ref"
                      title="注意: 请核对人员分配!"
                      type="error"
                      center
                      show-icon
                      :closable="false"
                    ></el-alert>
                  </template>
                </template>
                <template v-if="scope.row.ruleCode === 'BUSINESS_FOLLOW_UP'">
                  <template
                    v-if="
                      (iSnum3 != Number(scope.row.ratio) &&
                        scope.row.contentType === 'RATIO_TYPE') ||
                      (iSnum3 != Number(scope.row.money) && scope.row.contentType === 'MONEY_TYPE')
                    "
                  >
                    <el-alert
                      ref="alert_3_Ref"
                      title="注意: 请核对人员分配! "
                      type="error"
                      center
                      show-icon
                      :closable="false"
                    ></el-alert>
                  </template>
                </template>
                <template v-if="scope.row.ruleCode === 'LEAD_COMMUNICATION'">
                  <template
                    v-if="
                      (iSnum4 != Number(scope.row.ratio) &&
                        scope.row.contentType === 'RATIO_TYPE') ||
                      (iSnum4 != Number(scope.row.money) && scope.row.contentType === 'MONEY_TYPE')
                    "
                  >
                    <el-alert
                      ref="alert_4_Ref"
                      title="注意: 请核对人员分配! "
                      type="error"
                      center
                      show-icon
                      :closable="false"
                    ></el-alert>
                  </template>
                </template>
                <template v-if="scope.row.ruleCode === 'CONSULT_BIDDING_SCHEME'">
                  <template
                    v-if="
                      (iSnum6 != Number(scope.row.ratio) &&
                        scope.row.contentType === 'RATIO_TYPE') ||
                      (iSnum6 != Number(scope.row.money) && scope.row.contentType === 'MONEY_TYPE')
                    "
                  >
                    <el-alert
                      ref="alert_6_Ref"
                      title="注意: 请核对人员分配! "
                      type="error"
                      center
                      show-icon
                      :closable="false"
                    ></el-alert>
                  </template>
                </template>
                <template v-if="scope.row.ruleCode === 'REFERRER'">
                  <template
                    v-if="
                      (iSnum7 != Number(scope.row.ratio) &&
                        scope.row.contentType === 'RATIO_TYPE') ||
                      (iSnum7 != Number(scope.row.money) && scope.row.contentType === 'MONEY_TYPE')
                    "
                  >
                    <el-alert
                      ref="alert_7_Ref"
                      title="注意: 请核对人员分配! "
                      type="error"
                      center
                      show-icon
                      :closable="false"
                    ></el-alert>
                  </template>
                </template>
                <template v-if="scope.row.ruleCode === 'HISTORICAL_BUSINESS_REFERRER'">
                  <template
                    v-if="
                      (iSnum8 != Number(scope.row.ratio) &&
                        scope.row.contentType === 'RATIO_TYPE') ||
                      (iSnum8 != Number(scope.row.money) && scope.row.contentType === 'MONEY_TYPE')
                    "
                  >
                    <el-alert
                      ref="alert_8_Ref"
                      title="注意: 请核对人员分配!"
                      type="error"
                      center
                      show-icon
                      :closable="false"
                    ></el-alert>
                  </template>
                </template>
                <template v-if="scope.row.ruleCode === 'PROJECT_IMPLEMENTATION'">
                  <template
                    v-if="
                      (iSnum9 != Number(scope.row.ratio) &&
                        scope.row.contentType === 'RATIO_TYPE') ||
                      (iSnum9 != Number(scope.row.money) && scope.row.contentType === 'MONEY_TYPE')
                    "
                  >
                    <el-alert
                      ref="alert_9_Ref"
                      title="注意: 请核对人员分配!"
                      type="error"
                      center
                      show-icon
                      :closable="false"
                    ></el-alert>
                  </template>
                </template>
              </template>
            </el-table-column>
          </el-table>

          <OpinionArea
            v-if="!isModule && options.processInstId"
            ref="opinionArea"
            :title="'商务绩效方案审核进度'"
            :procInstId="options.processInstId"
            :instInvolved="instInvolved"
          ></OpinionArea>
        </div>
        <div class="fullScreenOperation" v-if="distriBution && !isModule">
          <template v-if="title === '绩效分配'">
            <el-button type="success" class="teal_bg" @click="addFn(1)">提交</el-button>
            <el-button type="success" @click="addFn(0)">{{
              tableData[Index][0].id ? '保存' : '暂存'
            }}</el-button>
          </template>
          <template v-if="options.examine">
            <el-button type="success" @click="examine(1)">通过</el-button>
            <el-button type="danger" @click="examine(2)">不通过</el-button>
          </template>
          <el-button
            v-if="
              options.reject && oldForm.status != 0 && oldForm.status != 100 && oldForm.status != 5
            "
            type="primary"
            @click="revocation"
            >撤回</el-button
          >
          <el-button
            type="success"
            v-if="
              title === '绩效详情' &&
              oldForm.performanceAllocationAuthority &&
              oldForm.status == 100
            "
            @click="addFn(0)"
            >保存</el-button
          >
          <SelectDialog
            v-if="options.addAuditor && oldForm.status == 10 && permission(['ADD_PUBLIC_AGENT'])"
            @selectDataChange="selectAddAuditor"
            style="display: inline-block"
          >
            <el-button slot="button" type="primary" :loading="loading"> 增加待办人 </el-button>
          </SelectDialog>
          <el-button class="return" type="info" @click="returnFn">返回</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      :title="opinionInfo.index == 2 ? '审批不通过' : '审批通过'"
      width="60%"
      :visible.sync="dialogFormVisible"
      :before-close="cancelFn"
      :append-to-body="true"
    >
      <el-form :model="opinionInfo" :rules="rules" ref="form">
        <el-form-item prop="opinion" v-if="opinionInfo.index == 2">
          <el-input
            v-model="opinionInfo.opinion"
            @change="$forceUpdate()"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="" v-else>
          <el-input
            v-model="opinionInfo.opinion"
            @change="$forceUpdate()"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" v-if="opinionInfo.index == 1" @click="examineFn(1)"
          >审批通过</el-button
        >
        <el-button type="danger" v-else-if="opinionInfo.index == 2" @click="examineFn(2)"
          >审批不通过</el-button
        >
        <el-button
          type="warning"
          style="margin-right: 36%; margin-left: 11%; width: 120px"
          plain
          :loading="loading"
          @click="cancelFn"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <!-- 新增用户 -->
    <el-dialog :visible.sync="showEdit" :before-close="cancelSelect" append-to-body width="65%">
      <div slot="title" style="color: #409eff">新增用户</div>
      <div class="mainList_operation_search">
        <DeptCascader
          style="width: 30%"
          :placeholder="'请选择部门'"
          :deptList="deptList"
          v-model="parameter.deptId"
        />

        <el-input
          class="mainList_operation_search_Name"
          style="width: 200px; margin: 0 8px 8px 8px"
          placeholder="姓名"
          clearable
          v-model="parameter.userName"
        ></el-input>
        <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)"
          >搜索</el-button
        >
        <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
      </div>
      <el-table
        v-loading="selectedListLoad"
        :data="selectedList"
        style="width: 100%"
        max-height="400"
        border
        id="selectedTable"
        :row-key="getRowKey"
        :row-class-name="tableRowClassName"
        @selection-change="handleCurrentChange"
        @row-click="handRowClick"
        :row-style="rowClass"
        ref="selectedTable"
      >
        <el-table-column
          type="selection"
          label="选择"
          :reserve-selection="true"
          :selectable="selectEnable"
          width="60"
        >
        </el-table-column>
        <el-table-column align="center" prop="deptNames" label="部门"> </el-table-column>
        <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
      </el-table>
      <div style="float: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChangeHandler"
          :current-page="parameter.pageNow"
          :page-size="parameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="total, prev, pager, next, slot, jumper"
          :total="parameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </div>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button type="success" style="min-width: 120px; margin: 0 25px" @click="saveSelectedFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          @click="cancelSelect"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <DeptTreeSelectDialog
      v-if="showDeptEdit"
      :isDeptTreeShow.sync="showDeptEdit"
      :selectedTreeData="arrDeptSelect"
      @getDeptList="submitDept"
    ></DeptTreeSelectDialog>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import { mapState, mapGetters } from 'vuex'
import { setBusinessPerformance } from '@/util/jsencrypt'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    DeptTreeSelectDialog: () => import('@/components/dept/DeptTreeSelectDialog.vue'),
  },

  props: {
    showEditDialog: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    distriBution: {
      type: Boolean,
      default: false,
    },
    Disabled: {
      type: Boolean,
      default: false,
    },
    isModule: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '绩效分配',
    },
  },
  computed: {
    ...mapState({
      businessInformation: state => state.business.businessInformation,
      userAssignmentMap: state => state.business.userAssignmentMap,
      CompanyMembers: state => state.business.CompanyMembers,
      userInfo: state => state.user.userInfo,
      selectDeptList: state => state.business.selectDeptList,
    }),
    // 公共-公司
    other() {
      let num = 100
      this.tableData[this.Index].forEach(v => {
        if (v.ruleCode !== 'OTHER') {
          num -= Number(v.ratio)
        }
      })
      num = Number(num.toFixed(2))
      return num
    },
    // 新、老客户-商务负责人
    newOldBusinessManager() {
      const businessType = this.tableData[this.Index][0].businessType
      let consultBiddingScheme = null
      let businessFollowUp = null
      let historicalBusinessReferrer = null
      let projectImplementation = null
      let sum = 97 //  商务负责人
      let num = 0
      if (businessType === '有事前沟通老客户项目') {
        //   // 实施团队以商务负责人的百分比
        //   consultBiddingScheme = this.tableData[this.Index].find(
        //     v => v.ruleCode === 'CONSULT_BIDDING_SCHEME'
        //   )
        //   businessFollowUp = this.tableData[this.Index].find(v => v.ruleCode === 'BUSINESS_FOLLOW_UP')
        //   historicalBusinessReferrer = this.tableData[this.Index].find(
        //     v => v.ruleCode === 'HISTORICAL_BUSINESS_REFERRER'
        //   )
        //   // 第六点--实施团队
        //   projectImplementation = this.tableData[this.Index].find(
        //     v => v.ruleCode === 'PROJECT_IMPLEMENTATION'
        //   )
        //   if (consultBiddingScheme) {
        //     sum -= Number(consultBiddingScheme.ratio)
        //   }
        //   if (businessFollowUp) {
        //     sum -= Number(businessFollowUp.ratio)
        //   }
        //   if (historicalBusinessReferrer) {
        //     sum -= Number(historicalBusinessReferrer.ratio)
        //   }
        //   let type = this.oldCustomer.businessManageRadioList[0]
        //     ? this.oldCustomer.businessManageRadioList[0]
        //     : 0
        //   if (type === 0) {
        //     // sum 商务负责人
        //     sum = 0
        //   } else if (type === 'OLD_KEY_MANAGER_CERTAINTY') {
        //     sum = sum //100
        //   } else if (type === 'OLD_KEY_MANAGER_COMPETITIVENESS') {
        //     sum = sum * 0.75 //75
        //   } else if (type === 'OLD_KEY_MANAGER_EFFECTIVE_INFORMATION') {
        //     sum = sum * 0.5 //50
        //   } else if (
        //     type === 'OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
        //     this.oldCustomer.managerNumBer > 0
        //   ) {
        //     sum = sum * (this.oldCustomer.managerNumBer / 100)
        //     sum = Math.round(sum * 100) / 100
        //   }
        //   if (projectImplementation) {
        //     num = sum / (1 + Number(projectImplementation.temporaryRatio) * 0.01)
        //     sum = Math.round(num * 100) / 100
        //     this.tableData[this.Index]
        //       .filter(v => v.ruleCode === 'PROJECT_IMPLEMENTATION')
        //       .forEach(v => {
        //         v.ratio =
        //           Math.round(num * (Number(projectImplementation.temporaryRatio) * 0.01) * 100) / 100
        //         if (v.userAssignment && v.userAssignment.length == 1) {
        //           v.userAssignment[0].userRatio = v.ratio
        //         }
        //       })
        //   }
        // 实施团队以商务负责人的百分比
        let SUM = 97
        let businessNum = 0
        consultBiddingScheme = this.tableData[this.Index].find(
          v => v.ruleCode === 'CONSULT_BIDDING_SCHEME'
        )
        businessFollowUp = this.tableData[this.Index].find(v => v.ruleCode === 'BUSINESS_FOLLOW_UP')
        historicalBusinessReferrer = this.tableData[this.Index].find(
          v => v.ruleCode === 'HISTORICAL_BUSINESS_REFERRER'
        )
        const other = this.tableData[this.Index].find(v => v.ruleCode === 'OTHER')
        // 第六点--实施团队
        projectImplementation = this.tableData[this.Index].find(
          v => v.ruleCode === 'PROJECT_IMPLEMENTATION'
        )
        if (consultBiddingScheme) {
          SUM -= Number(consultBiddingScheme.ratio)
        }
        if (businessFollowUp) {
          SUM -= Number(businessFollowUp.ratio)
        }
        if (historicalBusinessReferrer) {
          SUM -= Number(historicalBusinessReferrer.ratio)
        }
        let type = this.oldCustomer.businessManageRadioList[0]
          ? this.oldCustomer.businessManageRadioList[0]
          : 0
        if (type === 0) {
          //商务负责人
          businessNum = 0
        } else if (type === 'OLD_KEY_MANAGER_CERTAINTY') {
          businessNum = 1 //100
        } else if (type === 'OLD_KEY_MANAGER_COMPETITIVENESS') {
          businessNum = 0.75 //75
        } else if (type === 'OLD_KEY_MANAGER_EFFECTIVE_INFORMATION') {
          businessNum = 0.5 //50
        } else if (
          type === 'OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          Number(this.oldCustomer.managerNumBer) > 0
        ) {
          businessNum = Number(this.oldCustomer.managerNumBer) / 100
          businessNum = Number(((businessNum * 100) / 100).toFixed(2)) //5-30
        }
        if (projectImplementation) {
          // 实施团队 实际比例
          this.tableData[this.Index]
            .filter(v => v.ruleCode === 'PROJECT_IMPLEMENTATION')
            .forEach(v => {
              v.ratio = Number(
                ((SUM * (Number(projectImplementation.temporaryRatio) * 0.01) * 100) / 100).toFixed(
                  2
                )
              )
              if (v.userAssignment && v.userAssignment.length == 1) {
                v.userAssignment[0].userRatio = v.ratio
              }
            })
          // 商务负责人
          sum = Number(
            (SUM * (1 - Number(projectImplementation.temporaryRatio) * 0.01) * businessNum).toFixed(
              2
            )
          )
        }
      } else if (businessType === '有事前沟通新客户项目') {
        consultBiddingScheme = this.tableData[this.Index].find(
          v => v.ruleCode === 'CONSULT_BIDDING_SCHEME'
        )
        businessFollowUp = this.tableData[this.Index].find(v => v.ruleCode === 'BUSINESS_FOLLOW_UP')
        historicalBusinessReferrer = this.tableData[this.Index].find(v => v.ruleCode === 'REFERRER')
        if (consultBiddingScheme) {
          sum -= Number(consultBiddingScheme.ratio)
        }
        if (businessFollowUp) {
          sum -= Number(businessFollowUp.ratio)
        }
        if (historicalBusinessReferrer) {
          sum -= Number(historicalBusinessReferrer.ratio)
        }
        let type = this.newCustomers.businessManageRadioList[0]
          ? this.newCustomers.businessManageRadioList[0]
          : 0

        if (type === 0) {
          sum = 0
        } else if (type === 'NEW_KEY_MANAGER_CERTAINTY') {
          sum = sum //100
        } else if (type === 'NEW_KEY_MANAGER_COMPETITIVENESS') {
          sum = sum * 0.75 //75
        } else if (type === 'NEW_KEY_MANAGER_EFFECTIVE_INFORMATION') {
          sum = sum * 0.5 //50
        } else if (
          type === 'NEW_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          Number(this.newCustomers.managerNumBer) > 0
        ) {
          sum = sum * (Number(this.newCustomers.managerNumBer) / 100)
          sum = Number(sum.toFixed(2))
        }
      }
      return sum
    },
    // 直接竞标-商务负责人
    directBusinessManager() {
      const businessFollowUp = this.tableData[this.Index].find(
        v => v.ruleCode === 'BUSINESS_FOLLOW_UP'
      )
      const biddingScheme = this.tableData[this.Index].find(
        v => v.ruleCode === 'CONSULT_BIDDING_SCHEME'
      )
      const referrer = this.tableData[this.Index].find(v => v.ruleCode === 'REFERRER')

      let sum = 90
      let num = this.directBidding.businessManageRadioList[0]
        ? this.directBidding.businessManageRadioList[0]
        : 0
      if (businessFollowUp) {
        sum -= Number(businessFollowUp.ratio)
      }
      if (biddingScheme) {
        sum -= Number(biddingScheme.ratio)
      }
      if (referrer) {
        sum -= Number(referrer.ratio)
      }

      if (num === 0) {
        sum = 0
      } else if (num === 'COMPETITIVE_KEY_MANAGER_DECISIVE_SUPPORT') {
        sum = sum //100
      } else if (num === 'COMPETITIVE_KEY_MANAGER_EFFECTIVE_INFORMATION') {
        sum = sum * 0.5 //50
      } else if (
        num === 'COMPETITIVE_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
        Number(this.directBidding.managerNumBer) > 0
      ) {
        sum = sum * (Number(this.directBidding.managerNumBer) / 100)
        sum = Number(sum.toFixed(2))
      }
      return sum
    },

    // 新、老客户-信息提供人/公司
    CustomerInformationProvider() {
      let num = 0
      const businessType = this.tableData[this.Index][0].businessType
      let one = ''
      let two = ''
      let three = ''
      if (businessType === '有事前沟通老客户项目') {
        const oldOne =
          this.oldCustomer.informationProviderOneList[
            this.oldCustomer.informationProviderOneList.length - 1
          ]
        const oldTwo =
          this.oldCustomer.informationProviderTwoList[
            this.oldCustomer.informationProviderTwoList.length - 1
          ]
        const oldThree =
          this.oldCustomer.informationProviderThreeList[
            this.oldCustomer.informationProviderThreeList.length - 1
          ]
        if (oldOne === 'OLD_CUSTOMER_RATIFY_YES') {
          one = 1
        } else if (oldOne === 'OLD_CUSTOMER_RATIFY_NO') {
          one = 0.5
        }

        if (oldTwo === 'OLD_CUSTOMER_PROJECT_ABUNDANT_YES') {
          two = 1
        } else if (oldTwo === 'OLD_CUSTOMER_PROJECT_ABUNDANT_NO') {
          two = 0.5
        }

        if (oldThree === 'OLD_CUSTOMER_PROJECT_PRODUCE_OTHER') {
          three = 1
        } else if (oldThree === 'OLD_CUSTOMER_PROJECT_PRODUCE_CITY_LEVEL') {
          three = 0.5
        } else if (oldThree === 'OLD_CUSTOMER_PROJECT_PRODUCE_PROVINCIAL_LEVEL') {
          three = 0
        }
      } else if (businessType === '有事前沟通新客户项目') {
        const newOne =
          this.newCustomers.informationProviderOneList[
            this.newCustomers.informationProviderOneList.length - 1
          ]
        const newTwo =
          this.newCustomers.informationProviderTwoList[
            this.newCustomers.informationProviderTwoList.length - 1
          ]
        const newThree =
          this.newCustomers.informationProviderThreeList[
            this.newCustomers.informationProviderThreeList.length - 1
          ]
        if (newOne === 'NEW_CUSTOMER_RATIFY_YES') {
          one = 1
        } else if (newOne === 'NEW_CUSTOMER_RATIFY_NO') {
          one = 0.5
        }

        if (newTwo === 'NEW_CUSTOMER_PROJECT_ABUNDANT_YES') {
          two = 1
        } else if (newTwo === 'NEW_CUSTOMER_PROJECT_ABUNDANT_NO') {
          two = 0.5
        }
        if (newThree === 'NEW_CUSTOMER_PROJECT_PRODUCE_OTHER') {
          three = 1
        } else if (newThree === 'NEW_CUSTOMER_PROJECT_PRODUCE_CITY_LEVEL') {
          three = 0.5
        } else if (newThree === 'NEW_CUSTOMER_PROJECT_PRODUCE_PROVINCIAL_LEVEL') {
          three = 0
        }
      }
      if (one) {
        num += Number(one)
      }
      if (two) {
        num += Number(two)
      }
      if (three) {
        num += Number(three)
      }
      // const other = this.tableData[this.Index].findIndex(v => v.ruleCode === 'OTHER')
      // if (other !== -1) {
      //   if (businessType === '有事前沟通老客户项目' || businessType === '有事前沟通新客户项目') {
      //     this.tableData[this.Index][other].ratio = 3 - num
      //   }
      // }
      return num
    },
    iSnum1() {
      return this.calculateISnum(this.tableData[this.Index], 'PROVIDE_INFORMATION')
    },
    iSnum3() {
      return this.calculateISnum(this.tableData[this.Index], 'BUSINESS_FOLLOW_UP')
    },
    iSnum4() {
      return this.calculateISnum(this.tableData[this.Index], 'LEAD_COMMUNICATION')
    },
    iSnum6() {
      return this.calculateISnum(this.tableData[this.Index], 'CONSULT_BIDDING_SCHEME')
    },
    iSnum7() {
      return this.calculateISnum(this.tableData[this.Index], 'REFERRER')
    },
    iSnum8() {
      return this.calculateISnum(this.tableData[this.Index], 'HISTORICAL_BUSINESS_REFERRER')
    },
    iSnum9() {
      return this.calculateISnum(this.tableData[this.Index], 'PROJECT_IMPLEMENTATION')
    },
  },
  data() {
    return {
      value: '',
      Index: 0,
      AchievementInfo: {},
      tableData: [
        [
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '1、负责关键沟通与组织',
            ratioRule: '90%减去3、4、5项',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '2、提供有效项目信息',
            ratioRule: '100元或1%(低于200元按200元)',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '3、商务跟进',
            ratioRule: '0%~20%',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '4、有效引荐',
            ratioRule: '0%~30%',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'REFERRER',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '5、售前和投标技术方案编制',
            // workContent: '投标文件技术方案编制',
            ratioRule: '0%~40%',
            participatingDistributor: '售前和投标技术方案编制部门',
            // participatingDistributor: '投标技术方案编制部门',
            instructionMatters: '',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
            // ruleCode: 'BIDDING_SCHEME',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '6、其他',
            participatingDistributor: '公司',
            instructionMatters: '',
            ratio: 9,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
          },
        ],
        [
          {
            businessType: '有事前沟通新客户项目',
            workContent: '1、负责关键沟通与组织',
            ratioRule: '97%减去3、4、5项',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '2、提供有效项目信息',
            ratioRule: '1%~3%',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '3、商务跟进',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
            ratioRule: '0%~20%',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '4、有效引荐',
            ratioRule: '0%~30%',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'REFERRER',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '5、售前和投标技术方案编制',
            ratioRule: '0%~20%',
            ratio: null,
            interzonalExplain: '',
            // managerRatio: null,
            temporaryRatio: null,
            money: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '售前和投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '6、其他',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '公司',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
          },
        ],
        [
          {
            businessType: '有事前沟通老客户项目',
            workContent: '1、负责关键沟通与组织',
            ratioRule: '97%减去3、4、5、6项',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '2、提供有效项目信息',
            ratioRule: ' 1%~3%',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '3、商务跟进',
            ratioRule: '0%~20%',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '4、有效引荐（历史商务关系）',
            ratioRule: '0%~30%',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'HISTORICAL_BUSINESS_REFERRER',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '5、售前和投标技术方案编制',
            ratioRule: '0%~20%',
            ratio: null,
            interzonalExplain: '',
            // managerRatio: null,
            temporaryRatio: null,
            money: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '售前和投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '6、驻场或原项目实施',
            ratioRule: '0%~20%',
            ratio: null,
            interzonalExplain: '',
            // managerRatio: null,
            temporaryRatio: null,
            money: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '驻场团队或原实施团队所在部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROJECT_IMPLEMENTATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '7、其他',
            ratioRule: '剩余比例',
            ratio: null,
            money: null,
            interzonalExplain: '',
            // managerRatio: 0,
            temporaryRatio: null,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '公司',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRuleDetail: '',
          },
        ],
      ],
      businessManage: [],
      loading: false,
      disabled: this.Disabled || false,
      dictData: {
        businessType: [],
        companyType: [],
        // businessDisbursenentType: [],
        projectSource: [],
        businessLines: [],
      },
      //直接竞标
      directBidding: {
        radioList: [], //信息提供人
        deptRadioDetailList: [], //投标文件技术方案编制
        deptNameNumBer: '', //投标文件技术方案编制输入框
        deptNameRadioNumberArr: [], //投标文件技术方案编制-拼接数字
        manageRadioList: [], //商务跟进
        referrerList: [], //引荐人
        businessManageRadioList: [], //负责关键沟通与组织
        managerNumBer: '', //负责关键沟通与组织输入框
        managerNumberArr: [], //负责关键沟通与组织拼接数字
      },
      // 新客户
      newCustomers: {
        informationProviderOneList: [], //信息提供人
        informationProviderTwoList: [], //信息提供人
        informationProviderThreeList: [], //信息提供人
        deptRadioDetailList: [], //投标文件技术方案编制-
        deptNameNumBer: '', //投标文件技术方案编制输入框
        deptNameRadioNumberArr: [], //投标文件技术方案编制-拼接数字
        manageRadioList: [], //商务跟进
        referrerList: [], //引荐人
        businessManageRadioList: [], //负责关键沟通与组织
        managerNumBer: '', //负责关键沟通与组织输入框
        managerNumberArr: [], //负责关键沟通与组织拼接数字
      },
      // 老客户
      oldCustomer: {
        informationProviderOneList: [], //信息提供人
        informationProviderTwoList: [], //信息提供人
        informationProviderThreeList: [], //信息提供人
        deptRadioDetailList: [], //投标文件技术方案编制-
        deptNameNumBer: '', //投标文件技术方案编制输入框
        deptNameRadioNumberArr: [], //投标文件技术方案编制-拼接数字
        manageRadioList: [], //商务跟进
        referrerList: [], //引荐人
        previousProjectList: [], //上一项目实施团队
        businessManageRadioList: [], //负责关键沟通与组织
        managerNumBer: '', //负责关键沟通与组织输入框
        managerNumberArr: [], //负责关键沟通与组织拼接数字
      },
      dialogFormVisible: false,
      opinionInfo: {
        businessPerformanceRequstList: [],
        opinion: null,
        index: 0,
      },
      rules: {
        opinion: [
          {
            required: true,
            message: '请输入不通过的意见内容',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 500,
            message: '意见内容在0~500个字符',
            trigger: 'blur',
          },
        ],
      },
      businessPerformanceDeleteList: [], //移除工作内容数组
      userAssignmentDeleteList: [], //移除人员数组
      showDeptEdit: false, //控制选择部门显示与隐藏
      parameter: {
        deptId: null,
        userName: null,
        pageNow: 1,
        total: 1,
        pageSize: 50,
      },
      pageSize: 0,
      selectedListLoad: false,
      selectedList: [],
      deptList: [],
      temporaryList: [],
      showEdit: false,
      arrSelect: [], //表示当前表格中已选择的用户
      arrDeptSelect: [], //标识用户选择部门
      tableDataUserIndex: 0,
      selectRow: [], //用户选中高亮
      instInvolved: {}, //当前进度处理人数组
      oldForm: {}, //原始数据
    }
  },
  created() {
    this.getType()
    this.getData()
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    if (this.selectDeptList.length <= 0) {
      this.getDeptList()
    } else {
      this.deptList = this.selectDeptList
    }
  },
  filters: {
    // 添加部门前缀
    addDept(val, n) {
      let name = ''
      if (n && n.length > 0) {
        for (let index = 0; index < n.length; index++) {
          const v = n[index]
          if (v.userName == val) {
            var newArr = v.departmentName.split(',')
            name = v.userName + '-' + newArr[0]
            break
          }
        }
      } else {
        name = val
      }
      return name
    },
  },
  watch: {
    oldForm: {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          this.title == '绩效详情' &&
          val &&
          val.status == 100 &&
          val.performanceAllocationAuthority
        ) {
          this.disabled = false
        }
      },
    },
    // 直接竞标-商务负责人
    directBusinessManager: {
      // immediate: true,
      handler(val) {
        const businessManager = this.tableData[this.Index].findIndex(
          v => v.ruleCode === 'LEAD_COMMUNICATION'
        )
        if (businessManager !== -1) {
          // this.tableData[this.Index][businessManager].managerRatio = val
          this.tableData[this.Index][businessManager].ratio = val
            ? val
            : this.tableData[this.Index][businessManager].ratio
          if (
            this.tableData[this.Index][businessManager].userAssignment &&
            this.tableData[this.Index][businessManager].userAssignment.length == 1
          ) {
            this.tableData[this.Index][businessManager].userAssignment[0].userRatio = val
          }
        }
      },
    },
    // 新、老客户-信息提供人
    CustomerInformationProvider: {
      // immediate: true,
      handler(val) {
        const customerInformationProvider = this.tableData[this.Index].findIndex(
          v => v.ruleCode === 'PROVIDE_INFORMATION'
        )
        if (customerInformationProvider !== -1) {
          this.tableData[this.Index][customerInformationProvider].ratio = val
          if (
            this.tableData[this.Index][customerInformationProvider].userAssignment &&
            this.tableData[this.Index][customerInformationProvider].userAssignment.length == 1
          ) {
            this.tableData[this.Index][customerInformationProvider].userAssignment[0].userRatio =
              val
          }
        }
      },
    },
    // 新、老客户-商务负责人
    newOldBusinessManager: {
      // immediate: true,
      handler(val) {
        const businessManager = this.tableData[this.Index].findIndex(
          v => v.ruleCode === 'LEAD_COMMUNICATION'
        )
        if (businessManager !== -1) {
          // this.tableData[this.Index][businessManager].managerRatio = val
          this.tableData[this.Index][businessManager].ratio = val
            ? val
            : this.tableData[this.Index][businessManager].ratio
          if (
            this.tableData[this.Index][businessManager].userAssignment &&
            this.tableData[this.Index][businessManager].userAssignment.length == 1
          ) {
            this.tableData[this.Index][businessManager].userAssignment[0].userRatio = val
          }
        }
      },
    },
    // 公共-公司
    other: {
      immediate: true,
      handler(val) {
        const other = this.tableData[this.Index].find(v => v.ruleCode === 'OTHER')
        if (other) {
          other.ratio = val
          // if (other.businessType === '无事前沟通竞标类项目') {
          // other.ratio = val
          // }
        }
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.selectRow = []
        if (val.length > 0) {
          this.selectRow = val.map(v => v.id)
        }
      },
    },
  },
  mounted() {},
  methods: {
    // 新增审核人
    selectAddAuditor(row) {
      this.$api.addAuditor
        .addTaskSetAssignee({
          userId: row.id,
          processInstId: this.oldForm.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.returnFn()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 选择部门点击
    handleNodeClick(row, node, data) {
      if (this.defaultSelectDept.indexOf(row.deptId) == -1) {
        node.checked = !node.checked
      }
    },
    // 对所选行进行样式设置
    rowClass({ row, rowIndex }) {
      if (this.selectRow.includes(row.id)) {
        return { 'background-color': '#ede88c' }
      }
    },
    // 点击行选中
    handRowClick(row) {
      if (this.arrSelect.indexOf(row.id) == -1) {
        this.$refs.selectedTable.toggleRowSelection(row)
      }
    },
    // 选中高亮
    tableRowClassName({ row, column, rowIndex, columnIndex }) {
      if (this.arrSelect.indexOf(row.id) != -1) {
        return ['tableRowClassName']
      }
      return []
    },
    getRowKey(row) {
      return row.id
    },
    cancelSelect() {
      this.showEdit = false
      this.temporaryList = []
    },
    selectEnable(row, rowIndex) {
      if (this.arrSelect.indexOf(row.id) != -1) {
        return false
      }
      return true
    },
    // 新增
    addUserFn(scope) {
      this.tableDataUserIndex = scope.$index
      // 部门
      if (
        scope.row.ruleCode === 'CONSULT_BIDDING_SCHEME' ||
        scope.row.ruleCode === 'PROJECT_IMPLEMENTATION'
      ) {
        // 展开树形选择器时勾选已有的值
        this.arrDeptSelect = []
        if (scope.row.userAssignment) {
          this.arrDeptSelect = scope.row.userAssignment.map(item => item.deptId)
        }
        this.showDeptEdit = true
      } else {
        // 用户
        this.parameter.userName = null
        this.parameter.deptId = null
        this.parameter.pageNow = 1
        this.getSelectedList()
        // 展开树形选择器时勾选已有的值
        this.arrSelect = []
        if (scope.row.userAssignment) {
          this.arrSelect = scope.row.userAssignment.map(item => item.staffId)
        }
        this.showEdit = true
        this.selectedListLoad = true
        this.$nextTick(() => {
          this.selectedList.forEach(v => {
            if (this.arrSelect.indexOf(v.id) != -1) {
              this.$refs.selectedTable.toggleRowSelection(v, true)
            } else {
              this.$refs.selectedTable.toggleRowSelection(v, false)
            }
          })
          this.selectedListLoad = false
        })
      }
    },
    // 删除
    removeUserFn(index, row, i) {
      row.userAssignment.splice(index, 1)
      if (i.id) {
        this.userAssignmentDeleteList.push(i.id)
      }
    },
    // 单元格点击
    cellClickFn(row, column, cell, event) {
      if (
        column.property === 'workContent' ||
        column.property === 'participatingDistributor' ||
        column.property === 'ratioRule' ||
        column.property === 'ratio' ||
        column.label === '商务占比'
      ) {
        this.$nextTick(() => {
          if (this.$refs.refTable) {
            this.$refs.refTable.toggleRowExpansion(row)
          }
        })
      }
    },
    // 保存用户
    saveSelectedFn() {
      this.temporaryList.forEach(v => {
        if (this.arrSelect.indexOf(v.id) == -1) {
          if (v.userName && v.userName.length === 2) {
            v.userName = v.userName.slice(0, 1) + '　' + v.userName.slice(1)
          }

          this.tableData[this.Index][this.tableDataUserIndex].userAssignment.push({
            userType: 'MEMBER',
            businessId: this.oldForm.businessManage.id,
            staffId: v.id,
            postName: v.postName,
            deptNames: v.deptNames,
            userName: v.userName,
            phone: v.phone,
            nowUserMoney: 0,
            nowUserRatio: 0,
            userMoney: 0,
            userRatio: 0,
            performanceValue: 0,
            ruleCode: this.tableData[this.Index][this.tableDataUserIndex].ruleCode,
          })
        }
      })
      this.showEdit = false
      this.temporaryList = []
    },
    handleCurrentChange(val) {
      this.temporaryList = val
    },
    // 保存部门
    submitDept(val) {
      let arr = []
      if (this.tableData[this.Index][this.tableDataUserIndex].userAssignment instanceof Array) {
        arr = val.filter(
          item =>
            !this.tableData[this.Index][this.tableDataUserIndex].userAssignment.some(
              x => item.id == x.deptId
            )
        )
      }
      arr.forEach(e => {
        if (e.deptName && e.deptName.length === 2) {
          e.deptName = e.deptName.slice(0, 1) + '　' + e.deptName.slice(1)
        }
        this.tableData[this.Index][this.tableDataUserIndex].userAssignment.push({
          userType: 'DEPT',
          businessId: this.oldForm.businessManage.id,
          deptId: e.id,
          deptName: e.deptName,
          userRatio: 0,
          userMoney: 0,
          nowUserRatio: 0,
          nowUserMoney: 0,
          ruleCode: this.tableData[this.Index][this.tableDataUserIndex].ruleCode,
        })
      })
      this.showDeptEdit = false
    },
    handleReset() {
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getSelectedList()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getSelectedList()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 获取所有人员
    getSelectedList() {
      this.selectedListLoad = true
      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.selectedList = res.data?.records.deepClone()
          this.parameter.total = res.data?.total
          this.$nextTick(() => {
            this.selectedList.forEach(v => {
              if (this.arrSelect.indexOf(v.id) != -1) {
                this.$refs.selectedTable.toggleRowSelection(v, true)
              }
            })
          })
          this.selectedListLoad = false
        })
        .catch(err => {
          console.log(err)
          this.selectedListLoad = false
        })
    },
    //部门
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {})
    },
    // 撤回
    revocation() {
      this.loading = true

      this.$api.businessPerformance
        .reject({
          id: this.oldForm.id,
        })
        .then(res => {
          this.$message({
            message: '撤回成功!',
            type: 'success',
          })
          this.loading = false
          this.returnFn()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    managerBlurFn(key) {
      if (key === 'directBidding') {
        if (
          this.directBidding.businessManageRadioList[0] ===
            'COMPETITIVE_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          this.directBidding.managerNumBer < 5
        ) {
          this.directBidding.managerNumBer = 5
          this.$message.warning('输入值不能小于区间最小值')
        } else if (
          this.directBidding.businessManageRadioList[0] ===
            'COMPETITIVE_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          this.directBidding.managerNumBer > 30
        ) {
          this.directBidding.managerNumBer = 30
          this.$message.warning('输入值不能大于区间最大值')
        }
        this.directBidding.managerNumberArr = [
          `COMPETITIVE_ONE_${this.directBidding.managerNumBer}`,
        ]
      } else if (key === 'oldCustomer') {
        if (
          this.oldCustomer.businessManageRadioList[0] === 'OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          this.oldCustomer.managerNumBer < 5
        ) {
          this.oldCustomer.managerNumBer = 5
          this.$message.warning('输入值不能小于区间最小值')
        } else if (
          this.oldCustomer.businessManageRadioList[0] === 'OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          this.oldCustomer.managerNumBer > 30
        ) {
          this.oldCustomer.managerNumBer = 30
          this.$message.warning('输入值不能大于区间最大值')
        }
        this.oldCustomer.managerNumberArr = [`OLD_ONE_${this.oldCustomer.managerNumBer}`]
      } else if (key === 'newCustomers') {
        if (
          this.newCustomers.businessManageRadioList[0] ===
            'NEW_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          this.newCustomers.managerNumBer < 5
        ) {
          this.newCustomers.managerNumBer = 5
          this.$message.warning('输入值不能小于区间最小值')
        } else if (
          this.newCustomers.businessManageRadioList[0] ===
            'NEW_KEY_MANAGER_NAME_BUSINESS_MANAGER' &&
          this.newCustomers.managerNumBer > 30
        ) {
          this.newCustomers.managerNumBer = 30
          this.$message.warning('输入值不能大于区间最大值')
        }
        this.newCustomers.managerNumberArr = [`NEW_ONE_${this.newCustomers.managerNumBer}`]
      }
    },
    ratioBlurFn(row, key) {
      let [num1, num2] = row.interzonalExplain.split('-')
      this.tableData[this.Index].forEach(v => {
        if (row.ruleCode === v.ruleCode) {
          if (key === 'PROJECT_IMPLEMENTATION') {
            if (Number(v.temporaryRatio) < Number(num1)) {
              this.$message.warning('输入值不能小于区间最小值')
              v.temporaryRatio = Number(num1)
            } else if (Number(v.temporaryRatio) > Number(num2)) {
              this.$message.warning('输入值不能大于区间最大值')
              v.temporaryRatio = Number(num2)
            } else {
              v.temporaryRatio = Number(v.temporaryRatio).toFixed(2)
              v.temporaryRatio = Number(v.temporaryRatio)
            }
          } else {
            if (Number(v.ratio) < Number(num1)) {
              this.$message.warning('输入值不能小于区间最小值')
              v.ratio = Number(num1)
            } else if (Number(v.ratio) > Number(num2)) {
              this.$message.warning('输入值不能大于区间最大值')
              v.ratio = Number(num2)
            } else {
              v.ratio = Number(v.ratio).toFixed(2)
              v.ratio = Number(v.ratio)
            }
            if (v.userAssignment && v.userAssignment.length == 1) {
              v.userAssignment[0].userRatio = v.ratio
            }
          }
        }
      })
    },
    calculateISnum(arr, ruleCode) {
      let all = 0
      arr.forEach(v => {
        if (ruleCode === v.ruleCode) {
          if (v.contentType === 'MONEY_TYPE') {
            v.userAssignment.forEach(k => {
              if (k.ruleCode === ruleCode && k.userMoney) {
                all += Number(k.userMoney)
              }
            })
          } else if (v.contentType === 'RATIO_TYPE') {
            v.userAssignment.forEach(k => {
              if (k.ruleCode === ruleCode && k.userRatio) {
                all += Number(k.userRatio)
              }
            })
          }
        }
      })
      return all
    },
    // 公共-负责关键沟通与组织
    businessManageFn(val, key) {
      const num = val[val.length - 1] ? val[val.length - 1] : 0
      if (key === 'directBidding') {
        this.directBidding.businessManageRadioList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'LEAD_COMMUNICATION')
          .forEach(v => {
            if (num === 0) {
              v.ratio = null
              v.interzonalExplain = ''
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.directBidding.managerNumberArr = []
              this.directBidding.managerNumBer = ''
            } else if (num === 'COMPETITIVE_KEY_MANAGER_DECISIVE_SUPPORT') {
              //100
              v.interzonalExplain = ''
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.directBidding.managerNumberArr = []
              this.directBidding.managerNumBer = ''
            } else if (num === 'COMPETITIVE_KEY_MANAGER_EFFECTIVE_INFORMATION') {
              //50
              v.interzonalExplain = ''
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.directBidding.managerNumberArr = []
              this.directBidding.managerNumBer = ''
            } else if (num === 'COMPETITIVE_KEY_MANAGER_NAME_BUSINESS_MANAGER') {
              v.interzonalExplain = '5-30' //5-30
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            }
          })
      } else if (key === 'oldCustomer') {
        this.oldCustomer.businessManageRadioList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'LEAD_COMMUNICATION')
          .forEach(v => {
            if (num === 0) {
              v.ratio = null
              v.interzonalExplain = ''
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.oldCustomer.managerNumberArr = []
              this.oldCustomer.managerNumBer = ''
            } else if (num === 'OLD_KEY_MANAGER_CERTAINTY') {
              //100
              v.interzonalExplain = ''
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.oldCustomer.managerNumberArr = []
              this.oldCustomer.managerNumBer = ''
            } else if (num === 'OLD_KEY_MANAGER_COMPETITIVENESS') {
              //75
              v.interzonalExplain = ''
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.oldCustomer.managerNumberArr = []
              this.oldCustomer.managerNumBer = ''
            } else if (num === 'OLD_KEY_MANAGER_EFFECTIVE_INFORMATION') {
              v.interzonalExplain = '' //50
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.oldCustomer.managerNumberArr = []
              this.oldCustomer.managerNumBer = ''
            } else if (num === 'OLD_KEY_MANAGER_NAME_BUSINESS_MANAGER') {
              v.interzonalExplain = '5-30' //5-30
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            }
          })
      } else if (key === 'newCustomers') {
        this.newCustomers.businessManageRadioList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'LEAD_COMMUNICATION')
          .forEach(v => {
            if (num === 0) {
              v.interzonalExplain = ''
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.newCustomers.managerNumberArr = []
              this.newCustomers.managerNumBer = ''
            } else if (num === 'NEW_KEY_MANAGER_CERTAINTY') {
              v.interzonalExplain = '' //100
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.newCustomers.managerNumberArr = []
              this.newCustomers.managerNumBer = ''
            } else if (num === 'NEW_KEY_MANAGER_COMPETITIVENESS') {
              v.interzonalExplain = '' //75
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.newCustomers.managerNumberArr = []
              this.newCustomers.managerNumBer = ''
            } else if (num === 'NEW_KEY_MANAGER_EFFECTIVE_INFORMATION') {
              v.interzonalExplain = '' //50
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
              this.newCustomers.managerNumberArr = []
              this.newCustomers.managerNumBer = ''
            } else if (num === 'NEW_KEY_MANAGER_NAME_BUSINESS_MANAGER') {
              v.interzonalExplain = '5-30' //5-30
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            }
          })
      }
    },
    // 老客户-上一项目实施团队
    previousProjectFn(val, key) {
      const num = val[val.length - 1] ? val[val.length - 1] : 0
      if (key === 'oldCustomer') {
        this.oldCustomer.previousProjectList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'PROJECT_IMPLEMENTATION')
          .forEach(v => {
            if (num === 'OLD_CUSTOMER_IMPLEMENTATION_TEAM_NEAGTIVE_ATTITUDE') {
              v.ratio = 0
              v.temporaryRatio = 0
              v.interzonalExplain = ''
              v.money = 0
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_CUSTOMER_IMPLEMENTATION_TEAM_NOT_NEAGTIVE_ATTITUDE') {
              v.ratio = null
              v.temporaryRatio = null
              v.interzonalExplain = '0 - 10' //0-10
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_CUSTOMER_IMPLEMENTATION_TEAM_POSITIVE_ATTITUDE') {
              v.ratio = null
              v.temporaryRatio = null
              v.interzonalExplain = '10 - 20' //10-20
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_CUSTOMER_IMPLEMENTATION_TEAM_CONCLUSIVE') {
              v.ratio = null
              v.temporaryRatio = null
              v.interzonalExplain = '20 - 30' //20-30
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_SERVICE_UNIT_EFFECTIVE_INFORMATION') {
              v.interzonalExplain = ''
              // v.ratio = 0
              v.temporaryRatio = 20
              // v.money = 0
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_SERVICE_UNIT_NON_IMPLEMENTATION_AFFIRM') {
              v.interzonalExplain = ''
              // v.ratio = 0
              v.temporaryRatio = 10
              // v.money = 0
              v.contentType = 'RATIO_TYPE'
            } else if (num === 0) {
              v.ratio = null
              v.temporaryRatio = null
              v.interzonalExplain = ''
              v.money = null
              v.contentType = 'RATIO_TYPE'
            }
          })
      }
    },
    // 公共-有效引荐（历史商务关系）
    referrerFn(val, key) {
      const num = val[val.length - 1] ? val[val.length - 1] : 0
      if (key === 'directBidding') {
        this.directBidding.referrerList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'REFERRER')
          .forEach(v => {
            if (num === 'COMPETITIVE_CUSTOMER_REFERRER_GRUDG') {
              v.interzonalExplain = ''
              v.ratio = 0
              v.money = 0
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'COMPETITIVE_CUSTOMER_REFERRER_ORDINARY') {
              v.interzonalExplain = '0 - 5' //0-5
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'COMPETITIVE_CUSTOMER_REFERRER_SIGNIFICANCE') {
              v.interzonalExplain = '5 - 10' //5-10
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'COMPETITIVE_CUSTOMER_REFERRER_DECISION') {
              v.interzonalExplain = '10 - 20' //10-20
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'COMPETITIVE_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY') {
              v.interzonalExplain = '20 - 30' //20-30
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 0) {
              v.interzonalExplain = ''
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            }
          })
      } else if (key === 'oldCustomer') {
        this.oldCustomer.referrerList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'HISTORICAL_BUSINESS_REFERRER')
          .forEach(v => {
            if (num === 'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_GRUDG') {
              v.interzonalExplain = ''
              v.ratio = 0
              v.money = 0
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_ORDINARY') {
              v.interzonalExplain = '0 - 5' //0-5
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_SIGNIFICANCE') {
              v.interzonalExplain = '5 - 10' //5-10
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_DECISION') {
              v.interzonalExplain = '10 - 20' //10-20
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'OLD_CUSTOMER_HISTORICAL_BUSINESS_REFERRER_BUSINESS_INTERMEDIARY') {
              v.interzonalExplain = '20 - 30' //20-30
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 0) {
              v.interzonalExplain = ''
              v.ratio = 0
              v.money = 0
              v.contentType = 'RATIO_TYPE'
            }
          })
      } else if (key === 'newCustomers') {
        this.newCustomers.referrerList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'REFERRER')
          .forEach(v => {
            if (num === 'NEW_CUSTOMER_REFERRER_GRUDG') {
              v.interzonalExplain = ''
              v.ratio = 0
              v.money = 0
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'NEW_CUSTOMER_REFERRER_ORDINARY') {
              v.interzonalExplain = '0 - 5' //0-5
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'NEW_CUSTOMER_REFERRER_SIGNIFICANCE') {
              v.interzonalExplain = '5 - 10' //5-10
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'NEW_CUSTOMER_REFERRER_DECISION') {
              v.interzonalExplain = '10 - 20' //10-20
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 'NEW_CUSTOMER_REFERRER_BUSINESS_INTERMEDIARY') {
              v.interzonalExplain = '20 - 30' //20-30
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            } else if (num === 0) {
              v.interzonalExplain = ''
              v.ratio = null
              v.money = null
              v.contentType = 'RATIO_TYPE'
            }
          })
      }
    },
    // 新、老客户-提供有效信息
    informationProviderFn(val, key, type) {
      const num = val[val.length - 1] ? val[val.length - 1] : 0
      if (type === 'oldCustomer') {
        if (key === 'One') {
          this.oldCustomer.informationProviderOneList = [num]
        } else if (key === 'Two') {
          this.oldCustomer.informationProviderTwoList = [num]
        } else if (key === 'Three') {
          this.oldCustomer.informationProviderThreeList = [num]
        }
      } else if (type === 'newCustomers') {
        if (key === 'One') {
          this.newCustomers.informationProviderOneList = [num]
        } else if (key === 'Two') {
          this.newCustomers.informationProviderTwoList = [num]
        } else if (key === 'Three') {
          this.newCustomers.informationProviderThreeList = [num]
        }
      }
    },
    // 公用-商务跟进
    manageRadioFn(val, key) {
      const num = val[val.length - 1] ? val[val.length - 1] : 0
      if (key === 'directBidding') {
        this.directBidding.manageRadioList = [num]
      } else if (key === 'oldCustomer') {
        this.oldCustomer.manageRadioList = [num]
      } else if (key === 'newCustomers') {
        this.newCustomers.manageRadioList = [num]
      }
      this.tableData[this.Index]
        .filter(v => v.ruleCode === 'BUSINESS_FOLLOW_UP')
        .forEach(v => {
          if (
            num === 'COMPETITIVE_TENDER_BUSINESS_EXTERNAL' ||
            num === 'NEW_CUSTOMER_BUSINESS_EXTERNAL' ||
            num === 'OLD_CUSTOMER_BUSINESS_EXTERNAL'
          ) {
            v.interzonalExplain = '0 - 10' //0-10
            v.ratio = null
            v.money = null
            v.contentType = 'RATIO_TYPE'
          } else if (
            num === 'COMPETITIVE_TENDER_BUSINESS_NO_BUSINESS_PERSON' ||
            num === 'NEW_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON' ||
            num === 'OLD_CUSTOMER_BUSINESS_NO_BUSINESS_PERSON'
          ) {
            v.interzonalExplain = '0 - 20' //0-20
            v.ratio = null
            v.money = null
            v.contentType = 'RATIO_TYPE'
          } else if (num === 0) {
            v.interzonalExplain = ''
            v.ratio = null
            v.money = null
            v.contentType = 'RATIO_TYPE'
          }
        })
    },
    // 公用-投标技术方案编辑
    deptRadioDetailFn(val, key) {
      const num = val[val.length - 1] ? val[val.length - 1] : 0
      if (key === 'directBidding') {
        this.directBidding.deptRadioDetailList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'CONSULT_BIDDING_SCHEME')
          .forEach(v => {
            if (num === 'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT') {
              v.ratio = null
              v.interzonalExplain = ''
              v.money = null
              v.contentType = 'MONEY_TYPE'
              v.userAssignment.forEach(n => {
                n.contentType = 'MONEY_TYPE'
                n.userMoney = 0
                n.userRatio = 0
              })
              this.directBidding.deptNameNumBer = null
            } else {
              if (num === 'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN') {
                v.ratio = null
                v.interzonalExplain = '0 - 20' //0-20
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (
                num === 'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPEN_TENDERING'
              ) {
                v.ratio = null
                v.interzonalExplain = '0 - 15' //0-15
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (
                num ===
                'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
              ) {
                v.interzonalExplain = '0 - 10' //0-10
                v.ratio = null
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (
                num === 'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_ENQUIRY'
              ) {
                v.ratio = null
                v.interzonalExplain = ''
                v.money = 300
                v.contentType = 'MONEY_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'MONEY_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_GRUDGE') {
                v.ratio = 0
                v.interzonalExplain = ''
                v.money = 0
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 0) {
                v.ratio = null
                v.interzonalExplain = ''
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              }
            }
          })
        this.directBidding.deptNameRadioNumberArr = []
      } else if (key === 'oldCustomer') {
        this.oldCustomer.deptRadioDetailList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'CONSULT_BIDDING_SCHEME')
          .forEach(v => {
            if (num === 'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT') {
              v.ratio = null
              v.interzonalExplain = ''
              v.money = null
              v.contentType = 'MONEY_TYPE'
              v.userAssignment.forEach(n => {
                n.contentType = 'MONEY_TYPE'
                n.userMoney = 0
                n.userRatio = 0
              })
              this.oldCustomer.deptNameNumBer = null
            } else {
              if (num === 'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN') {
                v.ratio = null
                v.interzonalExplain = '0 -20' //0-20
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (
                num === 'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING'
              ) {
                v.ratio = null
                v.interzonalExplain = '0 - 15' //0-15
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (
                num === 'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
              ) {
                v.ratio = null
                v.interzonalExplain = '0 - 10' //0-10
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_ENQUIRY') {
                v.ratio = 0
                v.interzonalExplain = ''
                v.money = 300
                v.contentType = 'MONEY_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'MONEY_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_GRUDGE') {
                v.ratio = 0
                v.interzonalExplain = ''
                v.money = 0
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 0) {
                v.ratio = null
                v.interzonalExplain = ''
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              }
            }
          })
        this.oldCustomer.deptNameRadioNumberArr = []
      } else if (key === 'newCustomers') {
        this.newCustomers.deptRadioDetailList = [num]
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'CONSULT_BIDDING_SCHEME')
          .forEach(v => {
            if (num === 'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT') {
              v.ratio = null
              v.interzonalExplain = ''
              v.money = null
              v.contentType = 'MONEY_TYPE'
              v.userAssignment.forEach(n => {
                n.contentType = 'MONEY_TYPE'
                n.userMoney = 0
                n.userRatio = 0
              })
              this.newCustomers.deptNameNumBer = null
            } else {
              if (num === 'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_NOPRESALEPLAN') {
                v.ratio = null
                v.interzonalExplain = '0 - 20' //0-20
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (
                num === 'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_OPENTENDERING'
              ) {
                v.ratio = null
                v.interzonalExplain = '0 - 15' //0- 15
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (
                num === 'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_RIVALROUSNESS_CONSULT'
              ) {
                v.ratio = null
                v.interzonalExplain = '0 - 10' //0-10
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_ENQUIRY') {
                v.ratio = null
                v.interzonalExplain = ''
                v.money = 300
                v.contentType = 'MONEY_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'MONEY_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_GRUDGE') {
                v.ratio = 0
                v.interzonalExplain = ''
                v.money = 0
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              } else if (num === 0) {
                v.ratio = null
                v.interzonalExplain = ''
                v.money = null
                v.contentType = 'RATIO_TYPE'
                v.userAssignment.forEach(n => {
                  n.contentType = 'RATIO_TYPE'
                  n.userMoney = 0
                  n.userRatio = 0
                })
              }
            }
          })
        this.newCustomers.deptNameRadioNumberArr = []
      }
    },
    deptNameBlurFn(key) {
      if (key === 'directBidding') {
        if (
          this.directBidding.deptRadioDetailList[0] ===
            'COMPETITIVE_TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT' &&
          this.directBidding.deptNameNumBer < 0
        ) {
          this.directBidding.deptNameNumBer = 0
          this.$message.warning('当前总工时不能为负数')
        }
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'CONSULT_BIDDING_SCHEME')
          .forEach(v => {
            v.money = this.directBidding.deptNameNumBer * 200

            if (v.userAssignment && v.userAssignment.length == 1) {
              v.userAssignment[0].userMoney = v.money
            }
          })
        this.directBidding.deptNameRadioNumberArr = [
          `COMPETITIVE_TWO_${this.directBidding.deptNameNumBer}`,
        ]
      } else if (key === 'oldCustomer') {
        if (
          this.oldCustomer.deptRadioDetailList[0] ===
            'OLD_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT' &&
          this.oldCustomer.deptNameNumBer < 0
        ) {
          this.oldCustomer.deptNameNumBer = 0
          this.$message.warning('当前总工时不能为负数')
        }
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'CONSULT_BIDDING_SCHEME')
          .forEach(v => {
            v.money = this.oldCustomer.deptNameNumBer * 200
            if (v.userAssignment && v.userAssignment.length == 1) {
              v.userAssignment[0].userMoney = v.money
            }
          })
        this.oldCustomer.deptNameRadioNumberArr = [`OLD_TWO_${this.oldCustomer.deptNameNumBer}`]
      } else if (key === 'newCustomers') {
        if (
          this.newCustomers.deptRadioDetailList[0] ===
            'NEW_CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT_DEPT' &&
          this.newCustomers.deptNameNumBer < 0
        ) {
          this.newCustomers.deptNameNumBer = 0
          this.$message.warning('当前总工时不能为负数')
        }
        this.tableData[this.Index]
          .filter(v => v.ruleCode === 'CONSULT_BIDDING_SCHEME')
          .forEach(v => {
            v.money = this.newCustomers.deptNameNumBer * 200
            if (v.userAssignment && v.userAssignment.length == 1) {
              v.userAssignment[0].userMoney = v.money
            }
          })
        this.newCustomers.deptNameRadioNumberArr = [`NEW_TWO_${this.newCustomers.deptNameNumBer}`]
      }
    },
    // 直接竞标-提供有效信息
    radioFn(val) {
      const num = val[val.length - 1] ? val[val.length - 1] : 0
      this.directBidding.radioList = [num]
      this.tableData[this.Index]
        .filter(v => v.ruleCode === 'PROVIDE_INFORMATION')
        .forEach(v => {
          if (num === 'COMPETITIVE_TENDER_OFFER_PART_TIME_BUSINESS') {
            v.ratio = 0
            v.money = 100
            v.contentType = 'MONEY_TYPE'
            v.userAssignment.forEach(n => {
              n.contentType = 'MONEY_TYPE'
              n.userMoney = 0
              n.userRatio = 0
            })

            if (v.userAssignment && v.userAssignment.length == 1) {
              v.userAssignment[0].userMoney = 100
            }
          } else if (num === 'COMPETITIVE_TENDER_OFFER_FULL_TIME_BUSINESS') {
            v.ratio = 1
            v.money = null
            v.contentType = 'RATIO_TYPE'
            v.userAssignment.forEach(n => {
              n.contentType = 'RATIO_TYPE'
              n.userMoney = 0
              n.userRatio = 0
            })
            if (v.userAssignment && v.userAssignment.length == 1) {
              v.userAssignment[0].userRatio = 1
            }
          } else if (num === 0) {
            v.ratio = null
            v.money = null
            v.contentType = 'RATIO_TYPE'
            v.userAssignment.forEach(n => {
              n.contentType = 'RATIO_TYPE'
              n.userMoney = 0
              n.userRatio = 0
            })
          }
        })
    },
    async addFn(index) {
      if (index === 1 || this.oldForm.performanceAllocationAuthority) {
        let obj = this.$refs
        delete obj.refTable
        delete obj.opinionArea
        delete obj.selectedTable
        for (const key in obj) {
          if (obj.hasOwnProperty.call(obj, key)) {
            const element = obj[key]
            if (element) {
              return this.$message({
                message: '请核对绩效比例是否分配正确！',
                type: 'error',
              })
            }
          }
        }
        const direct = this.directBidding.businessManageRadioList
        const newCust = this.newCustomers.businessManageRadioList
        const oldCust = this.oldCustomer.businessManageRadioList
        if (
          (this.Index === 0 && direct.length == 0) ||
          (this.Index === 0 && direct[0] == 0) ||
          (this.Index === 1 && newCust.length == 0) ||
          (this.Index === 1 && newCust[0] == 0) ||
          (this.Index === 2 && oldCust.length == 0) ||
          (this.Index === 2 && oldCust[0] == 0)
        ) {
          this.$nextTick(() => {
            let scroll = document.getElementById('Scrollbar')
            if (scroll) {
              scroll.scrollTop = 0
            }
          })
          return this.$message({
            message: '表格第一项为必选项！',
            type: 'error',
          })
        }
      }
      let arr = []
      if (this.Index === 0) {
        arr = [
          ...this.directBidding.radioList,
          ...this.directBidding.deptRadioDetailList,
          ...this.directBidding.deptNameRadioNumberArr,
          ...this.directBidding.manageRadioList,
          ...this.directBidding.referrerList,
          ...this.directBidding.businessManageRadioList,
          ...this.directBidding.managerNumberArr,
        ]
      } else if (this.Index === 1) {
        arr = [
          ...this.newCustomers.informationProviderOneList,
          ...this.newCustomers.informationProviderTwoList,
          ...this.newCustomers.informationProviderThreeList,
          ...this.newCustomers.deptRadioDetailList,
          ...this.newCustomers.deptNameRadioNumberArr,
          ...this.newCustomers.manageRadioList,
          ...this.newCustomers.referrerList,
          ...this.newCustomers.businessManageRadioList,
          ...this.newCustomers.managerNumberArr,
        ]
      } else if (this.Index === 2) {
        arr = [
          ...this.oldCustomer.informationProviderOneList,
          ...this.oldCustomer.informationProviderTwoList,
          ...this.oldCustomer.informationProviderThreeList,
          ...this.oldCustomer.deptRadioDetailList,
          ...this.oldCustomer.deptNameRadioNumberArr,
          ...this.oldCustomer.manageRadioList,
          ...this.oldCustomer.referrerList,
          ...this.oldCustomer.previousProjectList,
          ...this.oldCustomer.businessManageRadioList,
          ...this.oldCustomer.managerNumberArr,
        ]
      }
      arr = arr.filter(v => v !== 0)
      this.AchievementInfo.ruleDetailStr = JSON.stringify(arr)
      this.AchievementInfo.businessPerformanceRequstList = this.tableData[this.Index]
      this.AchievementInfo.businessPerformanceDeleteList = this.businessPerformanceDeleteList
      this.AchievementInfo.userAssignmentDeleteList = this.userAssignmentDeleteList
      this.AchievementInfo.businessManageId = this.oldForm.businessManage.id
      this.AchievementInfo.id = this.oldForm.id
      // this.AchievementInfo.status = index
      // // 如果是撤回状态并且点击的是保存按钮
      // if (index == 0 && this.businessManage.performanceStatus == 5) {
      //   this.AchievementInfo.status = this.businessManage.performanceStatus
      // }
      // await this.$api.businessPerformance.saveOrUpdatePerformance(this.AchievementInfo)
      // if (index === 0) {
      //   Message.success('暂存成功')
      // } else {
      //   Message.success('提交成功')
      // }
      let i = 1
      if (index === 1) {
        // 有流程id并且状态为5，是驳回数据  状态为 0 并且有流程ID是撤回，
        if (
          (this.oldForm.processInstId && this.oldForm.status == 5) ||
          (this.oldForm.processInstId && this.oldForm.status == 0)
        ) {
          i = 2
        }
        this.businessPerformanceSubmit(this.AchievementInfo, i)
      } else if (index === 0) {
        if (this.oldForm.id) {
          i = 2
        }
        this.businessPerformanceStorage(this.AchievementInfo, i)
      }
    },
    // 提交 1   驳回提交 2
    async businessPerformanceSubmit(obj, index) {
      this.loading = true
      let res = null
      if (index === 1) {
        res = await this.$api.businessPerformance.startProcess(obj)
      } else if (index === 2) {
        res = await this.$api.businessPerformance.resubmitByReject(obj)
      }

      if (res.code == 200) {
        this.returnFn()
      }
      Message.success('提交成功')
      this.loading = false
    },
    // 暂存 1   暂存再编辑 2
    async businessPerformanceStorage(obj, index) {
      this.loading = true
      let res = null
      if (index === 1) {
        res = await this.$api.businessPerformance.tempAdd(obj)
      } else if (index === 2) {
        res = await this.$api.businessPerformance.undeterminedEdit(obj)
      }
      if (res.code == 200) {
        this.returnFn()
      }
      Message.success('操作成功')
      this.loading = false
    },

    async getData() {
      this.loading = true
      let res = null
      if (this.options.id) {
        res = await this.$api.businessPerformance.selectDetailById(this.options.id)
        this.instInvolved = {
          instInvolvedUserName: res.data.instInvolvedUserName ? res.data.instInvolvedUserName : [],
          status: res.data.status,
        }
      } else if (this.options.businessManageId) {
        res = await this.$api.businessPerformance.selectDetailByBusinessId(
          this.options.businessManageId
        )
      }
      this.oldForm = res.data.deepClone()
      if (res.data.businessManage) {
        if (res.data.businessManage.businessType === 'WSQGTJBLXM') {
          this.value = '无事前沟通竞标类项目'
          this.Index = 0
        } else if (res.data.businessManage.businessType === 'YSQGTXKHXM') {
          this.value = '有事前沟通新客户项目'
          this.Index = 1
        } else if (res.data.businessManage.businessType === 'YSQGTLKHXM') {
          this.value = '有事前沟通老客户项目'
          this.Index = 2
        }
        this.$store.commit({
          type: 'business/SET_BUSINESS_INFORMATION',
          businessInformation: res.data.businessManage,
        })
        this.$store.commit({
          type: 'business/SET_USER_ASSIGNMENT_MAP',
          userAssignmentMap: res.data.userAssignmentMap,
        })
        this.businessManage = res.data.businessManage
      }
      this.tableData[this.Index].forEach(v => {
        res.data.businessPerformanceList.forEach(k => {
          if (v.ruleCode === k.ruleCode) {
            if (k.ratio === 0) {
              v.ratio = k.ratio
            } else {
              v.ratio = k.ratio ? k.ratio : null
            }
            v.temporaryRatio = k.temporaryRatio ? k.temporaryRatio : null
            v.interzonalExplain = k.interzonalExplain ? k.interzonalExplain : ''
            v.id = k.id
            v.money = k.money ? k.money : null
            v.contentType = k.contentType
            v.instructionMatters = k.instructionMatters
          }
        })
      })
      if (res.data.userAssignmentMap?.userAssignment !== null) {
        this.tableData[this.Index].forEach((v, i) => {
          res.data.userAssignmentMap.userAssignment.forEach(k => {
            if (v.ruleCode === k.ruleCode) {
              if (k.userName && k.userName.length === 2) {
                k.userName = k.userName.slice(0, 1) + '　' + k.userName.slice(1)
              }
              if (k.deptName && k.deptName.length === 2) {
                k.deptName = k.deptName.slice(0, 1) + '　' + k.deptName.slice(1)
              }
              k.userMoney = k.userMoney ? k.userMoney : 0
              k.userRatio = k.userRatio ? k.userRatio : 0
              v.userAssignment.push(k)
            }
          })
        })
      }
      this.$nextTick(() => {
        if (this.$refs.refTable && this.tableData[this.Index][0]) {
          this.$refs.refTable.toggleRowExpansion(this.tableData[this.Index][0])
        }
      })
      let arr = res.data.ruleDetailStr ? JSON.parse(res.data.ruleDetailStr) : []
      let newStr = ''
      let oldStr = ''
      let mewManagerStr = ''
      let oldManagerStr = ''
      arr = arr.filter(v => v !== 0)
      arr.forEach(v => {
        if (v.indexOf('TWO') > 0) {
          newStr = v
          oldStr = v
        } else if (v.indexOf('ONE') > 0) {
          mewManagerStr = v
          oldManagerStr = v
        }
      })
      let str = newStr.split('_')
      let managerStr = mewManagerStr.split('_')
      if (this.Index === 0) {
        if (str && str.length > 2) {
          this.directBidding.deptNameNumBer = str[2]
          this.directBidding.deptNameRadioNumberArr = [oldStr]
        }
        if (managerStr && managerStr.length > 2) {
          this.directBidding.managerNumBer = managerStr[2]
          this.directBidding.managerNumberArr = [oldManagerStr]
        }
        this.directBidding.radioList = arr.filter(v => v.indexOf('TIME_BUSINESS') > 0)
        this.directBidding.deptRadioDetailList = arr.filter(
          v => v.indexOf('TENDER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT') > 0
        )
        this.directBidding.manageRadioList = arr.filter(v => v.indexOf('TENDER_BUSINESS') > 0)
        this.directBidding.referrerList = arr.filter(v => v.indexOf('CUSTOMER_REFERRER') > 0)
        this.directBidding.businessManageRadioList = arr.filter(v => v.indexOf('KEY_MANAGER') > 0)
      } else if (this.Index === 1) {
        if (str && str.length > 2) {
          this.newCustomers.deptNameNumBer = str[2]
          this.newCustomers.deptNameRadioNumberArr = [oldStr]
        }
        if (managerStr && managerStr.length > 2) {
          this.newCustomers.managerNumBer = managerStr[2]
          this.newCustomers.managerNumberArr = [oldManagerStr]
        }
        this.newCustomers.informationProviderOneList = arr.filter(
          v => v.indexOf('CUSTOMER_RATIFY') > 0
        )
        this.newCustomers.informationProviderTwoList = arr.filter(
          v => v.indexOf('CUSTOMER_PROJECT_ABUNDANT') > 0
        )
        this.newCustomers.informationProviderThreeList = arr.filter(
          v => v.indexOf('CUSTOMER_PROJECT_PRODUCE') > 0
        )
        this.newCustomers.deptRadioDetailList = arr.filter(
          v => v.indexOf('CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT') > 0
        )
        this.newCustomers.manageRadioList = arr.filter(v => v.indexOf('CUSTOMER_BUSINESS') > 0)
        this.newCustomers.referrerList = arr.filter(v => v.indexOf('CUSTOMER_REFERRER') > 0)
        this.newCustomers.businessManageRadioList = arr.filter(v => v.indexOf('KEY_MANAGER') > 0)
      } else if (this.Index === 2) {
        if (str && str.length > 2) {
          this.oldCustomer.deptNameNumBer = str[2]
          this.oldCustomer.deptNameRadioNumberArr = [oldStr]
        }
        if (managerStr && managerStr.length > 2) {
          this.oldCustomer.managerNumBer = managerStr[2]
          this.oldCustomer.managerNumberArr = [oldManagerStr]
        }
        this.oldCustomer.informationProviderOneList = arr.filter(
          v => v.indexOf('CUSTOMER_RATIFY') > 0
        )
        this.oldCustomer.informationProviderTwoList = arr.filter(
          v => v.indexOf('CUSTOMER_PROJECT_ABUNDANT') > 0
        )
        this.oldCustomer.informationProviderThreeList = arr.filter(
          v => v.indexOf('CUSTOMER_PROJECT_PRODUCE') > 0
        )
        this.oldCustomer.deptRadioDetailList = arr.filter(
          v => v.indexOf('CUSTOMER_BID_PRESALESPLAN_PURCHASE_DEPARTMENT') > 0
        )
        this.oldCustomer.manageRadioList = arr.filter(v => v.indexOf('CUSTOMER_BUSINESS') > 0)
        this.oldCustomer.referrerList = arr.filter(
          v => v.indexOf('CUSTOMER_HISTORICAL_BUSINESS_REFERRER') > 0
        )
        this.oldCustomer.previousProjectList = arr.filter(
          v => v.indexOf('CUSTOMER_IMPLEMENTATION_TEAM') > 0
        )
        this.oldCustomer.businessManageRadioList = arr.filter(v => v.indexOf('KEY_MANAGER') > 0)
      }
      this.businessPerformanceDeleteList = []
      this.userAssignmentDeleteList = []
      const oldArr = this.tableData[this.Index].map(item => item.ruleCode)
      res.data?.businessPerformanceList.forEach(k => {
        if (oldArr.indexOf(k.ruleCode) == -1) {
          this.businessPerformanceDeleteList.push(k.id)
        }
      })
      res.data?.userAssignmentMap?.userAssignment.forEach(k => {
        if (oldArr.indexOf(k.ruleCode) == -1) {
          this.userAssignmentDeleteList.push(k.id)
        }
      })
      this.loading = false
    },

    returnFn() {
      this.$emit('updateData')
    },
    examine(index) {
      this.opinionInfo.index = index
      this.opinionInfo.opinion = null
      this.dialogFormVisible = true
    },
    // 1 通过   2 不通过
    async examineFn(index) {
      let arr = []
      if (this.Index === 0) {
        arr = [
          ...this.directBidding.radioList,
          ...this.directBidding.deptRadioDetailList,
          ...this.directBidding.deptNameRadioNumberArr,
          ...this.directBidding.manageRadioList,
          ...this.directBidding.referrerList,
          ...this.directBidding.businessManageRadioList,
          ...this.directBidding.managerNumberArr,
        ]
      } else if (this.Index === 1) {
        arr = [
          ...this.newCustomers.informationProviderOneList,
          ...this.newCustomers.informationProviderTwoList,
          ...this.newCustomers.informationProviderThreeList,
          ...this.newCustomers.deptRadioDetailList,
          ...this.newCustomers.deptNameRadioNumberArr,
          ...this.newCustomers.manageRadioList,
          ...this.newCustomers.referrerList,
          ...this.newCustomers.businessManageRadioList,
          ...this.newCustomers.managerNumberArr,
        ]
      } else if (this.Index === 2) {
        arr = [
          ...this.oldCustomer.informationProviderOneList,
          ...this.oldCustomer.informationProviderTwoList,
          ...this.oldCustomer.informationProviderThreeList,
          ...this.oldCustomer.deptRadioDetailList,
          ...this.oldCustomer.deptNameRadioNumberArr,
          ...this.oldCustomer.manageRadioList,
          ...this.oldCustomer.referrerList,
          ...this.oldCustomer.previousProjectList,
          ...this.oldCustomer.businessManageRadioList,
          ...this.oldCustomer.managerNumberArr,
        ]
      }

      this.opinionInfo.ruleDetailStr = JSON.stringify(arr)
      this.opinionInfo.businessPerformanceRequstList = this.tableData[this.Index]
      this.opinionInfo.businessPerformanceDeleteList = this.businessPerformanceDeleteList
      this.opinionInfo.userAssignmentDeleteList = this.userAssignmentDeleteList
      // this.opinionInfo.isCheck = index
      this.opinionInfo.businessManageId = this.oldForm.businessManage.id
      this.opinionInfo.id = this.oldForm.id
      delete this.opinionInfo.index
      if (index === 1) {
        this.opinionInfo.opinion = this.opinionInfo.opinion ? this.opinionInfo.opinion : '通过'
        let obj = this.$refs
        delete obj.refTable
        delete obj.form
        delete obj.opinionArea
        delete obj.selectedTable

        for (const key in obj) {
          if (obj.hasOwnProperty.call(obj, key)) {
            const element = obj[key]
            if (element) {
              return this.$message({
                message: '请核对绩效比例是否分配正确！',
                type: 'error',
              })
            }
          }
        }
        this.loading = true
        const disposeTask = await this.$api.businessPerformance.disposeTask(this.opinionInfo)

        //  审核通过-分配人员比列金额
        if (disposeTask.code == 200) {
          let actualPerformance =
            this.oldForm?.businessPerformanceAdjustment?.actualPerformance || 0
          setBusinessPerformance(
            actualPerformance,
            this.tableData[this.Index],
            this.oldForm.businessManage
          )
        }
        this.dialogFormVisible = false
        this.loading = false
        this.returnFn()
        Message.success('审批通过')
      } else {
        this.$refs.form.validate(async valid => {
          if (valid) {
            this.loading = true
            await this.$api.businessPerformance.approvalReject(this.opinionInfo)
            this.dialogFormVisible = false
            this.loading = false
            this.returnFn()
            Message.success('审批不通过')
          }
        })
      }
    },
    cancelFn() {
      this.dialogFormVisible = false
      this.$refs.form?.resetFields()
    },
    getType() {
      // 避免用户进来直接去商务绩效查看，获取不到部门前缀
      if (this.CompanyMembers && this.CompanyMembers.length == 0) {
        this.$api.user
          .staffAllList({})
          .then(res => {
            this.$store.commit({
              type: 'business/SET_COMPANY_MEBERS',
              CompanyMembers: res.data,
            })
          })
          .catch(err => {
            console.log(err)
          })
      }
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.userFirst::before {
  content: '元';
  position: absolute;
  top: 0px;
  right: 8px;
}
.userPercentage::before {
  content: '%';
  position: absolute;
  top: 0px;
  right: 8px;
}
.first::before {
  content: '元';
  position: absolute;
  // top: 9px;
  right: 8px;
  color: #000;
}
.percentage::before {
  content: '%';
  position: absolute;
  // top: 9px;
  right: 8px;
  color: #000;
}
.Percentage::before {
  content: '%';
  position: absolute;
  top: 11px;
  right: 8px;
  color: #000;
}

.userProportion {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  margin-bottom: 3px;
}

/deep/.el-loading-spinner {
  margin-top: 21%;
}

// 禁用后的复选框字体高亮
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #606266 !important;
  cursor: text !important;
}
// 复选改单选样式-中心圆点
/deep/.el-checkbox__inner::after {
  transform: translate(-50%, -50%) scale(1) !important;
  transition: transform 0.15s ease-in, -webkit-transform 0.15s ease-in !important;
  width: 4px !important;
  height: 4px !important;
  border-radius: 100% !important;
  background-color: #fff !important;
  content: '' !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
}
//复选改单选框样式-外层圆圈
/deep/.el-checkbox__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
// 复选改单选选中样式
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #409eff !important;
  background: #409eff !important;
}

/deep/.el-table__expand-icon {
  color: red;
  font-size: 18px;
}
.proportion {
  color: red;
  width: 65px;
  text-align: center;
  display: inline-block;
}
.deptClass {
  color: #000;
  font-weight: 700;
  font-size: 15px;
  line-height: 43px;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}

/deep/.el-descriptions__title {
  width: 100% !important;
}
.info-title-with-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text_Remove_Bgc {
  //删除
  color: red;
  font-size: 26px;
}
.text_Addt_Bgc {
  //新增
  color: #66b1ff;
  font-size: 26px;
}
.dept-select {
  display: flex;
  flex-direction: column;
  .dept-select-item {
    height: 26px;
    line-height: 26px;
  }
}
.user-main {
  display: flex;
  align-items: center;
  .user-container {
    width: 85px;
    margin-left: 2px;
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 隐藏溢出文本 */
    text-overflow: ellipsis; /* 显示省略号 */
  }
}
/deep/#selectedTable {
  .tableRowClassName {
    background-color: #ede88c;
  }

  tbody tr:hover > td {
    background-color: unset !important; //修改成自己想要的颜色即可
  }
}
/deep/#refTable {
  .el-table__expand-column .cell {
  }

  /*修改展开按钮的样式 start*/
  .el-table__expand-icon {
    font-size: 12px;
    white-space: nowrap;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
  }
  /*2.展开按钮未点击的样式*/
  .el-table__expand-icon .el-icon-arrow-right:after {
    content: '展开细则';
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(6%);
  }
  .el-table__expand-icon .el-icon-arrow-right:before {
    content: '';
  }
  /*3.展开按钮点击后的样式*/
  .el-table__expand-icon--expanded .el-icon-arrow-right:after {
    content: '关闭细则';
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(6%);
  }
  .el-table__expand-icon--expanded .el-icon-arrow-right:before {
    content: '';
  }

  tbody tr:hover > td {
    background-color: #fdf6ec !important; //修改成自己想要的颜色即可
  }
  // 还原 input 组件禁用后的置灰效果
  .el-input.is-disabled .el-input__inner {
    background-color: #f5f7fa !important;
    border-color: #e4e7ed !important;
    color: #c0c4cc !important;
    cursor: not-allowed;
  }
}
</style>
